import React from 'react'

import Confetti from 'react-confetti';
const ConfettiPage = () => {
  return (
    <>
        <Confetti
          style={{position:"fixed", top:0, left:0, width:"100vw",height:'100vh', zIndex:9327900}}
          width={window.outerWidth}
          height={window.outerHeight}
          recycle={true}
          numberOfPieces={500}
          initialVelocityX={5}
          initialVelocityY={10}
        />
    </>
  )
}

export default ConfettiPage