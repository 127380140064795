import React, { useEffect  } from "react";
import { Input, Form, DatePicker, Button, Divider, Col, Row } from "antd";
import moment from 'moment';
import { useLocation } from "react-router-dom/cjs/react-router-dom";

function Page1({ formData, setFormData, form }) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  var email = searchParams.get('key');
  var token = searchParams.get('token');
  var alternateEmail = searchParams.get('ae');
  email = atob(email);
  const alternateInnitialEmail = atob(alternateEmail);
  useEffect(()=>{

        const formattedData = {
            ...formData,
            email: email,
            ...(formData.dob ? { dob: moment(formData.dob) } : {}),
            ...(formData.start_year_1 ? { start_year_1: moment(formData.start_year_1) } : {}),
            ...(formData.end_year_1 ? { end_year_1: moment(formData.end_year_1) } : {}),
            ...(formData.start_year_2 ? { start_year_2: moment(formData.start_year_2) } : {}),
            ...(formData.end_year_2 ? { end_year_2: moment(formData.end_year_2) } : {}),
        };
        form.setFieldsValue(formattedData);
    },[formData])
  return (
    <div>
       <Form
          layout="inline"
          form={form}
          onValuesChange={(changedValues, allValues) => {
            setFormData({ ...formData, ...allValues, token:token });
        }}
          className="gx-modal-box-form-item">
            <div style={{width:"100%",textAlign:"center",fontWeight:"bold",fontSize:"20px"}}>Onboarding Information With American Chase</div>
            <div style={{width:"100%",textAlign:"center", fontSize:"15px"}}>Congratulations on your selection with American Chase! To proceed, kindly complete the form as soon as possible.</div>
            <Divider/>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Input.Group>
                <Form.Item label='First Name'
                           rules={[{
                             required: true,
                             message: 'Please enter first name!'
                           },
                           {
                             pattern: /^[a-zA-Z0-9\s]+$/,
                             message: 'Special characters are not allowed!'
                           }]}
                           name={'first_name'}>
                  <Input type="text" autoComplete="off"/>
                </Form.Item>
              </Input.Group>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Input.Group>
                <Form.Item label='Middle Name'
                           name={'middle_name'} 
                           rules={[{
                              pattern: /^[a-zA-Z0-9\s]+$/,
                              message: 'Special characters are not allowed!'
                            }]}
                        >
                  <Input type="text" autoComplete="off"/>
                </Form.Item>
              </Input.Group>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Input.Group>
                <Form.Item label='Last Name'
                           rules={[{
                             required: true,
                             message: 'Please enter last name!'
                           },{
                            pattern: /^[a-zA-Z0-9\s]+$/, 
                            message: 'Special characters are not allowed!'
                          }]}
                           name={'last_name'}>
                  <Input type="text" autoComplete="off"/>
                </Form.Item>
              </Input.Group>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Input.Group>
                <Form.Item label='Email Address'
                           rules={[{
                             required: true,
                             message: 'Please enter email!'
                           },{
                            type: 'email',
                            message: 'Invalid email!'
                        }]}
                           name={'email'}>
                  <Input type="email" disabled autoComplete="off"/>
                </Form.Item>
              </Input.Group>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Input.Group>
                <Form.Item label='Alternate Email 1'
                           initialValue={alternateInnitialEmail}
                           rules={[{
                            type: 'email',
                            message: 'The input is not a valid email!'
                        }]}
                        name={['alternate_emails' , 'email1']}>
                  <Input type="email" autoComplete="off"/>
                </Form.Item>
              </Input.Group>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Input.Group>
                <Form.Item label='Alternate Phone Number'
                           rules={[{max:15 , message: "Characters should be less than or equal to 15"}]}
                          name={['alternate_phone']}>
                  <Input type="number" autoComplete="off"/>
                </Form.Item>
              </Input.Group>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Input.Group>
                {/* <Form.Item label='Phone Number'
                           rules={[{
                             required: true,
                             message: 'Please enter Phone Number!'
                           },{
                            validator: (_, value) => 
                                value && value.length >=9
                                    ? Promise.resolve() 
                                    : Promise.reject(new Error('Should be more than 9 digits!'))
                        },{max:15 , message: "Characters should be less than or equal to 15"}]}
                           name={'phone'}>
                  <Input type="number" autoComplete="off"/>
                </Form.Item> */}
                 <Row gutter={1} >
            <Col span={6} >
              <Form.Item
                label="Country Code"
                name="countryCode"
                // initialValue={consultant && consultant.profile && consultant.profile.phone_number 
                //     && (consultant.profile.phone_number.country_code !== undefined) && `+${consultant.profile.phone_number.country_code}`}
                rules={[{ required: true, message: 'Please enter country code!' },
                { 
                  validator: (_, value) => {
                    const countryCodeRegex = /^\+\d{1,4}$/ ;
                    if (value.length > 4) {
                      return Promise.reject(new Error('Characters should be less than or equal to 4!'));
                    }
                    if (!countryCodeRegex.test(value)) {
                      return Promise.reject(new Error('Please enter a valid country code (e.g., +XX)'));
                    }
                    return Promise.resolve();
                  } 
                }
              ]}
              style={{marginRight:0}}
              >
                <Input placeholder="+91" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Contact Number"
                name="phone"
                // initialValue={consultant && consultant.profile && consultant.profile.phone_number &&
                //   consultant.profile.phone_number.national_number && (consultant.profile.phone_number.national_number).replace(/\D/g, '')}
                rules={[{ required: true, message: 'Please enter mobile number!' }, {
                  validator: (_, value) => 
                  value && value.length >9
                  ? Promise.resolve() 
                  : Promise.reject(new Error('Should be more than 9 digits!'))
                },{max:11 , message: "Characters should be less than or equal to 11"}]}
                style={{marginRight:0}}
              >
                <Input placeholder="Mobile Number" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Ext."
                name="ext_Code"
                // initialValue={consultant && consultant.profile && consultant.profile.phone_number && consultant.profile.phone_number.extension}
                rules={[
                { 
                  validator: (_, value) => {
                    const countryCodeRegex = /^\d{1,5}$/ ;
                    if (!value) {
                      return Promise.resolve();
                    }
                    if(value && value.length > 5){
                      return Promise.reject(new Error('Please enter Characters less than or equal to 5!'));
                    }
                    if (!countryCodeRegex.test(value)) {
                      return Promise.reject(new Error('Please enter numbers only!'));
                    }
                    return Promise.resolve();
                  } 
                }]}
                style={{marginRight:"20px"}}
              >
                <Input placeholder="12345" />
              </Form.Item>
            </Col>
      </Row>
              </Input.Group>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Input.Group>
                <Form.Item label='Last 4 Digits SSN'
                           rules={[{
                             required: true,
                             message: 'Please enter SSN!'
                           },{
                            validator: (_, value) => 
                                value && value.length === 4 
                                    ? Promise.resolve() 
                                    : Promise.reject(new Error('SSN should be 4 digits!'))
                        }]}
                           name={'ssn'}>
                  <Input type="number" maxLength={4} autoComplete="off"/>
                </Form.Item>
              </Input.Group>
            </Col>
            
            
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label="Date of Birth"
                name={'dob'}
                initialValue={moment().subtract(18, 'years')}
                rules={[
                    {
                        required: true,
                        message: 'Please enter your date of birth!'
                    },
                    {
                        validator: (_, value) => 
                            value && moment().diff(value, 'years') >= 18 
                                ? Promise.resolve() 
                                : Promise.reject(new Error('Must be atleast 18 years old!'))
                    }
                ]}
              >
                <DatePicker
                  format="MM/DD/YYYY"
                  autoComplete="off"
                  className="ant-input"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Input.Group>
                <Form.Item label='Current Address'
                           rules={[{
                             required: true,
                             message: 'Please enter current address!'
                           }]}
                           name={'current_address'}>
                  <Input type="text" autoComplete="off"/>
                </Form.Item>
              </Input.Group>
            </Col>
            {/* <Divider/>
            <div style={{width:"100%",textAlign:"center",fontSize:"15px", fontWeight:"bold"}}>Current Address</div>
           <Divider/>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Input.Group>
                <Form.Item label='Street'
                           rules={[{
                             required: true,
                             message: 'Please enter street address!'
                           }]}
                           name={'street'}>
                  <Input type="text" autoComplete="off"/>
                </Form.Item>
              </Input.Group>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Input.Group>
                <Form.Item label='City'
                           rules={[{
                             required: true,
                             message: 'Please enter city!'
                           }]}
                           name={'city'}>
                  <Input type="text" autoComplete="off"/>
                </Form.Item>
              </Input.Group>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Input.Group>
                <Form.Item label='State'
                           rules={[{
                             required: true,
                             message: 'Please enter state!'
                           }]}
                           name={'state'}>
                  <Input type="text" autoComplete="off"/>
                </Form.Item>
              </Input.Group>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Input.Group>
                <Form.Item label='Zip Code'
                           rules={[{
                             required: true,
                             message: 'Please enter zip code!'
                           }]}
                           name={'zip_code'}>
                  <Input type="number" autoComplete="off"/>
                </Form.Item>
              </Input.Group>
            </Col> */}
            <Divider/>
            <div style={{width:"100%",textAlign:"center",fontWeight:"bold",fontSize:"15px"}}>Emergency Details</div>
            <Divider/>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Input.Group>
                <Form.Item label='Name' name="emergency_name" rules={[{ required: true, message: "Please enter name!" },{
                             pattern: /^[a-zA-Z0-9\s]+$/,
                             message: 'Special characters are not allowed!'
                           }]}>
                  <Input type="text" autoComplete="off"/>
                </Form.Item>
              </Input.Group>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Input.Group>
                <Form.Item label='Email' name="emergency_email"  rules={[{
                             required: true,
                             message: 'Please enter email!'
                           },{
                            type: 'email',
                            message: 'The input is not a valid email!'
                        }]}>
                  <Input type="email" autoComplete="off"/>
                </Form.Item>
              </Input.Group>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Input.Group>
                <Form.Item label='Phone' type='text'  name="emergency_phone"
                rules={[{
                    required: true,
                    message: 'Please enter Phone Number!'
                  },{
                    validator: (_, value) => 
                        value && value.length >= 10
                            ? Promise.resolve() 
                            : Promise.reject(new Error('Phone should be atleast 10 digits!'))
                }]}>
                  <Input type="number" autoComplete="off"/>
                </Form.Item>
              </Input.Group>
            </Col>
            
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Input.Group>
                <Form.Item label='Relation' name='emergency_relation' rules={[{
                             required: true,
                             message: 'Please enter Relation!'
                           },{
                            pattern: /^[a-zA-Z\s]+$/,
                            message: 'Only alphabets are not allowed!'
                          }]}>
                  <Input type="text" autoComplete="off"/>
                </Form.Item>
              </Input.Group>
            </Col>
            <Divider />
            <Col span={2}>
            </Col>
        </Form>
    </div>
  );
}

export default Page1;
