export const EmployerCompanyOptions = [
    {
      'id': 0,
      'handle': 'American Chase',
      'title': 'American Chase',
    },
    {
      'id': 1,
      'handle': 'JumboIT',
      'title': 'JumboIT',
    },
    {
      'id': 2,
      'handle': 'NuQode',
      'title': 'NuQode',
    },
    {
      'id': 3,
      'handle': 'Mindsburg',
      'title': 'Mindsburg',
    },
    {
      'id': 4,
      'handle': 'Asoid Group',
      'title': 'Asoid Group',
    },{
      'id': 5,
      'handle': 'Other',
      'title': 'Other',
      },
]
