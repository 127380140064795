import React from "react";
import {Redirect, Switch} from "react-router";
import asyncComponent from "../../util/asyncComponent";
import {PropsRoute} from "../../util/routeRestriction";

const AsyncMarketerOngoingOffers = asyncComponent(() => import('./ongoingOffers/index'));
const AsyncMarketerStandup = asyncComponent(() => import('./standup/index'));
const AsyncConsultant = asyncComponent(() => import('./consultant'));
const AsyncConsultantList = asyncComponent(()=>import('./consultant/edit'));
const AsyncSubmission = asyncComponent(()=>import('./submission'));
const AsyncSubmissionEdit = asyncComponent(() => import('./submission/edit'));
const AsyncProfileList = asyncComponent(() => import('./profiles'));
const AsyncOfferList = asyncComponent(() => import('./offer'));
const AsyncAssessmentList = asyncComponent(() => import('./assessment'));
const AsyncMarketerList = asyncComponent(() => import('./marketer'));
const AsyncScreening = asyncComponent(() => import('./screening'));
const AsyncMarketerEdit = asyncComponent(() => import('./marketer/edit'));
const AsyncIpComponent  = asyncComponent(() => import('./contact/ip'));
const AsyncClientComponent = asyncComponent(() => import('./contact/client'));
const AsyncVendorComponent = asyncComponent(() => import('./contact/vendor'));
const AsyncPocComponent = asyncComponent(() => import('./contact/poc'));
const AsyncContactComponent = asyncComponent(() => import('./contact'));
const AsyncRemoveDuplicates = asyncComponent(() => import('./contact/RemoveDuplicateClients'));

const Marketing = (props) => (

  <Switch>
    <Redirect exact from={`${props.match.url}/marketing`} to={`${props.match.url}`}/>
    <PropsRoute path={`${props.match.url}/standup`} dispatch={props.dispatch}
                component={AsyncMarketerStandup} user={props.user}/>
    <PropsRoute path={`${props.match.url}/consultant/list`} dispatch={props.dispatch}
                component={AsyncConsultant} user={props.user}/>
    <PropsRoute path={`${props.match.url}/ongoingoffers`} dispatch={props.dispatch}
                component={AsyncMarketerOngoingOffers} user={props.user}/>
    <PropsRoute path={`${props.match.url}/consultant/edit/:consultantId`} dispatch={props.dispatch}
                component={AsyncConsultantList} user={props.user}/>

    <PropsRoute path={`${props.match.url}/contact/list`} dispatch={props.dispatch}
                component={AsyncContactComponent} user={props.user}/>
    <PropsRoute path={`${props.match.url}/vendor/list`} dispatch={props.dispatch}
                component={AsyncVendorComponent} user={props.user}/>
    <PropsRoute path={`${props.match.url}/poc/list`} dispatch={props.dispatch}
                component={AsyncPocComponent} user={props.user}/>
    <PropsRoute path={`${props.match.url}/client/list`} dispatch={props.dispatch}
                component={AsyncClientComponent} user={props.user}/>
    <PropsRoute path={`${props.match.url}/partner/list`} dispatch={props.dispatch}
                component={AsyncIpComponent} user={props.user}/>

    <PropsRoute path={`${props.match.url}/submission/list`} dispatch={props.dispatch}
                component={AsyncSubmission} user={props.user}/>
    <PropsRoute path={`${props.match.url}/submission/edit/:submissionId`} dispatch={props.dispatch}
                component={AsyncSubmissionEdit} user={props.user}/>

    <PropsRoute path={`${props.match.url}/profiles/list`} dispatch={props.dispatch}
                component={AsyncProfileList} user={props.user}/>

    <PropsRoute path={`${props.match.url}/mergeClient`} dispatch={props.dispatch}
                component={AsyncRemoveDuplicates} user={props.user}/>
               
    <PropsRoute path={`${props.match.url}/offer/list`} dispatch={props.dispatch}
                component={AsyncOfferList} user={props.user}/>

    <PropsRoute path={`${props.match.url}/assessment/list`} dispatch={props.dispatch}
                component={AsyncAssessmentList} user={props.user}/>

    <PropsRoute path={`${props.match.url}/calendar/invite/:screener`} dispatch={props.dispatch}
                component={AsyncScreening} user={props.user}/>

    <PropsRoute path={`${props.match.url}/marketer/list`} dispatch={props.dispatch}
                component={AsyncMarketerList} user={props.user}/>
                
    <PropsRoute path={`${props.match.url}/marketer/edit/:marketerId`} dispatch={props.dispatch}
                component={AsyncMarketerEdit} user={props.user}/>
  </Switch>
);

export default Marketing;
