import { axiosAPI } from '../../util/Api';
const get = (apiEndpoint, params) => {
  return axiosAPI.get(apiEndpoint, {params}).then((response)=>{
       return response.data;
    }).catch(function (error) {
      if (error.response) {
        return Promise.reject(error.response)
    } else if (error.request) {
      return "Something went wrong. Please bear with us while we fix this."
    } else {
      console.log("error")
    }
      })
};

const patch = (apiEndpoint, payload, headers) => {
    return axiosAPI.patch(apiEndpoint, payload, headers).then((response)=>{
       return response.data;
    }).catch(function (error) {
      console.log("error >>>>>>>>>>>>>> ", error)
      console.log("error.response >>>>>>>>>>>>>> ", error.response)
      if (error.response) {
        return Promise.reject(error.response)
    } else if (error.request) {
      return "Something went wrong. Please bear with us while we fix this."
    } else {
      console.log("error")
    }
      })
};

const post = (apiEndpoint, payload, headers) => {

    return axiosAPI.post(apiEndpoint, payload, headers).then((response)=>{
       return response.data;
    }).catch(function (error) { 
      if (error.response) {
        return Promise.reject(error.response)
    } else if (error.request) {
      return "Something went wrong. Please bear with us while we fix this."
    } else {
      console.log("error")
    }
    })
};
const put = (apiEndpoint, payload, headers = null) => {
    return axiosAPI.put(apiEndpoint, payload, headers).then((response)=>{
        return response.data;
    }).catch(function (error) {
      if (error.response) {
        return Promise.reject(error.response)
    } else if (error.request) {
      return "Something went wrong. Please bear with us while we fix this."
    } else {
      console.log("error")
    }
      })
};

const upload = (apiEndpoint, file, headers, fileName=null) => {
  let formData = new FormData();
  if(fileName === null){
    formData.append("file", file);
  }else{
    formData.append(fileName, file);
  }

  return axiosAPI.patch(apiEndpoint, formData, headers).then((response)=>{
    return response.data;
  }).catch(function(error){
    return error;
  })
};


const multipartForm = (apiEndpoint, data) => {
  return axiosAPI.post(apiEndpoint, data, {

    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const destroy = (apiEndpoint) => {
  return axiosAPI.delete(apiEndpoint).then((response)=>{
       return response.data;
    }).catch(function (error) {
      if (error.response) {
        return Promise.reject(error.response)
    } else if (error.request) {
      return "Something went wrong. Please bear with us while we fix this."
    } else {
      console.log("error")
    }
      })
};

export const userService = {
    get,
    post,
    put,
    patch,
    upload,
  destroy,
  multipartForm
  // makeGetRequest
};
