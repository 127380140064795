import IntlMessages from "../../util/IntlMessages";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import React from "react";
import {useDispatch} from "react-redux";

import {THEME_TYPE_LITE} from "../../constants/ThemeSetting";
import {getUser} from "../../appRedux/actions/Users";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;


const CommonSidebar = ({navStyleClass, defaultOpenKeys, selectedKeys, themeType, user}) => {
  const dispatch = useDispatch();
  const getUserProfile = () => {
        dispatch(getUser());
    };

  return (
         <Menu defaultOpenKeys={[defaultOpenKeys]} selectedKeys={[selectedKeys]} theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'} mode="inline">
           <MenuItemGroup key="social-apps" className="gx-menu-group" >
             <SubMenu key="social-apps" popupClassName={navStyleClass}
                 title={<span> <i className="icon icon-wall"/>
                    <span><IntlMessages id="sidebar.profile"/></span></span>}>
               <Menu.Item key="user-profile">
                <Link to={"/username/profile/view".replace('username', user.username)} onClick={() => getUserProfile()}>
                  <i className="icon icon-view-o"/>
                  <span><IntlMessages id="sidebar.extensions.profile"/></span>
                </Link>
              </Menu.Item>

              <Menu.Item key="edit-user-profile">
                            <Link to={"/username/profile/edit".replace('username', user.username)} onClick={()=>getUserProfile()}>
                              <i className="icon icon-edit"/>
                              <span><IntlMessages id="sidebar.extensions.editprofile"/></span>
                            </Link>
              </Menu.Item>
             </SubMenu>
            </MenuItemGroup>
         </Menu>
);
};
export default CommonSidebar;
