import React, {useEffect, useState} from "react";
import { Input, Form, DatePicker, Divider, Col, Row, Upload, message, Select } from "antd";
import InboxOutlined from "@ant-design/icons/lib/icons/InboxOutlined";

function Emergencydetails({ formDataBackground, setFormDataBackground, form }) {
    useEffect(()=>{
        console.log('formdata3page', formDataBackground)
    },[])
    const { Dragger } = Upload;
    const {Option} = Select;
    // const [form] = Form.useForm();
   
    const [fileList, setFileList] = useState([]);
    const acceptableFileTypes = ['jpg', 'jpeg', 'png', 'gif', 'pdf'];
    const filterFilesByType = (fileList) => {
      return fileList.map(file => {
          const fileExtension = file.name.split('.').pop().toLowerCase();
          const isAcceptableFileType = acceptableFileTypes.includes(fileExtension);
  
          if (!isAcceptableFileType) {
              message.error(`Unsupported file type: ${file.name}`);
              return null;
          }
  
          return file;
      }).filter(Boolean); 
  };
    const handleFileChange = (info) => {
        let updatedFileList = [...info.fileList];

        updatedFileList = updatedFileList.filter((file) => {
            if (file.status === 'error') {
                message.error(`${file.name} file upload failed.`);
            }
            return file.status !== 'error';
        });
        updatedFileList = filterFilesByType(updatedFileList);
        setFileList(updatedFileList);
    };
    const handleBeforeUpload = (file) => {
        return false;
    };
     
    const [fileListI9, setFileListI9] = useState([]);
    const [fileListSSNFront, setFileListSSNFront] = useState([]);
    const [fileListSSNBack, setFileListSSNBack] = useState([]);

    useEffect(()=>{
        if(formDataBackground && formDataBackground.i9_form && formDataBackground.i9_form.fileList){
            setFileListI9(formDataBackground.i9_form.fileList )
        }
        if(formDataBackground && formDataBackground.ssn_front_copy && formDataBackground.ssn_front_copy.fileList){
            setFileListSSNFront(formDataBackground.ssn_front_copy.fileList )
        }
        if(formDataBackground && formDataBackground.ssn_back_copy && formDataBackground.ssn_back_copy.fileList){
            setFileListSSNBack(formDataBackground.ssn_back_copy.fileList )
        }
    },[])
   
    const handleFileChangeI9 = (info) => {
        let updatedFileList = [...info.fileList];
        updatedFileList = updatedFileList.filter((file) => {
            if (file.status === 'error') {
                message.error(`${file.name} file upload failed.`);
            }
            return file.status !== 'error';
        });
        updatedFileList = filterFilesByType(updatedFileList);
        setFileListI9(updatedFileList);
    };
    const handleFileChangeSSNFront = (info) => {
        let updatedFileList = [...info.fileList];
        updatedFileList = updatedFileList.filter((file) => {
            if (file.status === 'error') {
                message.error(`${file.name} file upload failed.`);
            }
            return file.status !== 'error';
        });
        updatedFileList = filterFilesByType(updatedFileList);
        setFileListSSNFront(updatedFileList);
    };


    const handleFileChangeSSNBack = (info) => {
        let updatedFileList = [...info.fileList];
        updatedFileList = updatedFileList.filter((file) => {
            if (file.status === 'error') {
                message.error(`${file.name} file upload failed.`);
            }
            return file.status !== 'error';
        });
        updatedFileList = filterFilesByType(updatedFileList);
        setFileListSSNBack(updatedFileList);
    };
    const uploadProps = {
        multiple: false,
        fileList,
        beforeUpload: handleBeforeUpload,
        onChange: handleFileChange,
    };
  return (
    <div>
        <Form
          layout="inline"
          form={form}
          initialValues={formDataBackground}
          onValuesChange={(changedValues, allValues) => {
            // if(!('i9_form' in changedValues || 'ssn_front_copy' in changedValues || 'ssn_back_copy' in changedValues )){
            //     const updatedFormData = {
            //         ...formDataBackground,
            //         ...allValues
            //       };
            //       localStorage.setItem('formDataBackground', JSON.stringify(updatedFormData));
            // }
            setFormDataBackground({ ...formDataBackground, ...allValues });
            }}
          className="gx-modal-box-form-item">
            <div style={{width:"100%",textAlign:"center",fontWeight:"bold",fontSize:"15px"}}>I-9 Form</div>
            <Divider/>
              <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                <Form.Item label="Please upload I-9 Form" name='i9_form' rules={[{ required: true, message: 'Please upload I-9 Form!' }]}>
                    <Dragger {...uploadProps} fileList={fileListI9} onChange={handleFileChangeI9}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    </Dragger>
                </Form.Item>
            </Col>
              <Divider/>
              <div style={{width:"100%",textAlign:"center",fontWeight:"bold",fontSize:"15px"}}>SSN Copy</div>
            <Divider/>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label="SSN Front Copy" name='ssn_front_copy' rules={[{ required: true, message: 'Please upload SSN Front Copy!' }]}>
                    <Dragger {...uploadProps} fileList={fileListSSNFront} onChange={handleFileChangeSSNFront}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    </Dragger>
                </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label="SSN Back Copy" name='ssn_back_copy' rules={[{ required: true, message: 'Please upload SSN Back Copy!' }]}>
                    <Dragger {...uploadProps} fileList={fileListSSNBack} onChange={handleFileChangeSSNBack}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    </Dragger>
                </Form.Item>
            </Col>
            <Divider/>
            <div style={{width:"100%",textAlign:"center",fontWeight:"bold",fontSize:"15px"}}>Emergency Contact Details 1</div>
            <Divider/>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label="Name" name={['emergency_contact_1', 'name']} 
                        rules={[{ required: true, message: 'Please enter name!' },{
                            pattern: /^[a-zA-Z0-9\s]+$/,
                            message: 'Special characters are not allowed!'
                        }]}>
                    <Input placeholder="Name" />
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label="Email" 
                name={['emergency_contact_1', 'email']}
                rules={[{
                    required: true,
                    message: 'Please enter email!'
                  },{
                   type: 'email',
                   message: 'The input is not a valid email!'
               }]}
                >
                    <Input placeholder="Email" />
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label="Contact" name={['emergency_contact_1', 'contact']}  rules={[{ required: true, message: 'Please enter contact number!' },{max:15 , message: "Characters should be less than or equal to 15"}]}>
                    <Input type="number" placeholder="Contact Number" />
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label="Relation" name={['emergency_contact_1', 'relation']} rules={[{ required: true, message: 'Please enter relation!' },{
                            pattern: /^[a-zA-Z\s]+$/,   
                            message: 'Only alphabets are allowed!'
                        }]}>
                    <Input placeholder="Relation" />
                </Form.Item>
            </Col>

        <Divider/>
            <div style={{width:"100%",textAlign:"center",fontWeight:"bold",fontSize:"15px"}}>Emergency Contact Details 2</div>
        <Divider/>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="Name" name={['emergency_contact_2', 'name']}   
                        rules={[{ required: true, message: 'Please enter name!' },{
                            pattern: /^[a-zA-Z0-9\s]+$/,   
                            message: 'Special characters are not allowed!'
                        }]}>
                <Input placeholder="Name" />
            </Form.Item>
        </Col>
         <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="Email"
             name={['emergency_contact_2', 'email']}
             rules={[{
                required: true,
                message: 'Please enter email!'
              },{
               type: 'email',
               message: 'The input is not a valid email!'
           }]}>
                <Input type='email' placeholder="Email" />
            </Form.Item>
         </Col>
         <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="Contact" name={['emergency_contact_2', 'contact']}  
             rules={[{ required: true, message: 'Please enter contact number!' },{max:15 , message: "Characters should be less than or equal to 15"}]}>
                <Input type="number" placeholder="Contact Number" />
            </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="Relation" name={['emergency_contact_2', 'relation']}  rules={[{ required: true, message: 'Please enter relation!' },{
                            pattern: /^[a-zA-Z\s]+$/,   
                            message: 'Only alphabets are allowed!'
                        }]}>
                <Input placeholder="Relation"  />
            </Form.Item>
        </Col>

            <Divider />
            <Col span={2}>
                          
            </Col>
        </Form>
    </div>
  );
}

export default Emergencydetails;
