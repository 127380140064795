import {
  INIT_URL,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  TOKEN_FAILURE,
  SIGNIN_USER_FAILURE,
  SET_SIGNIN_ERROR

} from "../../constants/ActionTypes";


const INIT_STATE = {
  token: JSON.parse(sessionStorage.getItem('access')),
  refresh_token: JSON.parse(sessionStorage.getItem('refresh')),
  initURL: '',
  authUser: JSON.parse(sessionStorage.getItem('authUser')),
  isLocked: false,
  isAuthenticated: JSON.parse(sessionStorage.getItem('isAuthenticated')),
};

const setSession = (payload) =>
{
  sessionStorage.setItem('access', JSON.stringify(payload.access));
  sessionStorage.setItem('refresh', JSON.stringify(payload.refresh));
  sessionStorage.setItem("authUser", JSON.stringify(payload.user));
  console.log("uuuuuuuuuuussssssseeeeeeerrrrrrrData", payload.user)
  sessionStorage.setItem('isAuthenticated', 'true');
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNUP_USER_SUCCESS: {
      setSession(action.payload);
      return {
        ...state,
        loader: false,
        authUser: action.payload.user,
        token: action.payload.access,
        refresh_token: action.payload.refresh,
        isAuthenticated: true
      }
    }
    case SIGNIN_USER_SUCCESS: {
      setSession(action.payload);
      return {
        ...state,
        loader: false,
        token: action.payload.access,
        refresh_token: action.payload.refresh,
        authUser: action.payload.user,
        isAuthenticated: true
      }
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }
    case SIGNOUT_USER_SUCCESS: {
      sessionStorage.removeItem('access');
      sessionStorage.removeItem('refresh');
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('authUser');
      localStorage.removeItem('openTab');
      sessionStorage.removeItem('subId');
      sessionStorage.removeItem('self');
      sessionStorage.removeItem('scr');
      return {
        ...state,
        token: null,
        authUser: null,
        loggedInUser: null,
        refresh_token: null,
        isAuthenticated: false,
        initURL: '/signin',
      }
    }

    case SIGNIN_USER_FAILURE:{
      return {
         ...state,
         invalidCredentials:true
      }
    }
    case SET_SIGNIN_ERROR: {
      return {
        ...state,
        invalidCredentials:false
      }
    }
    case TOKEN_FAILURE: {
      return {
         ...state,
         initURL: '/',
         token: null,
         refresh_token: null,
         authUser: null,
         loggedInUser: null,
         isAuthenticated:false
      }
    }
    default:
      return state;
  }
}
