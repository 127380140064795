export const getFormData = (values, selectedPhoto) => {
  values['work_email'] = values['work_email'] ? values['work_email'] + '@americanchase.com' : '';
  values['email'] = values['work_email'] ? values['work_email'] : values['email'];
  values['salary'] = 0;
  values['is_removed'] = false;
  if (selectedPhoto) {
    values['photo'] = selectedPhoto;
  } else {
    values['photo'] = ''
  }
  const formData = new FormData();
  Object.keys(values).map((item) => {
    formData.append(item, values[item])
  });
  return formData;
};


export const  toTitleCase = (words) => {
    const capitalized = words.map(function(word) {
    return word.charAt(0).toUpperCase() + word.substring(1, word.length);
});
  return capitalized.join(" ");
};

// export const toTitleCase = (str) => {
//     return this.replace(/\w\S*/g, function (txt) {
//       return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//     });
// };
