import React from "react";
import {Redirect, Switch} from "react-router";
import asyncComponent from "../../util/asyncComponent";
import {PropsRoute} from "../../util/routeRestriction";

const AsyncEmployeeComponent = asyncComponent(() => import('./employee'));
const AsyncEmployeeEditComponent = asyncComponent(() => import('./employee/edit'));
const Employee = (props) => (

  <Switch>
    <Redirect exact from={`${props.match.url}/employee`} to={`${props.match.url}`}/>
    <PropsRoute path={`${props.match.url}/list`} dispatch={props.dispatch}
                component={AsyncEmployeeComponent} user={props.user}/>
    <PropsRoute path={`${props.match.url}/edit/:employeeId`} dispatch={props.dispatch}
                component={AsyncEmployeeEditComponent} user={props.user}/>
  </Switch>
);

export default Employee;
