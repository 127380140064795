import { message } from "antd";

export const handleErrorsInDifferentKey = (error) => {
    if (error && error.data) {
      if (Array.isArray(error.data)) {
        error.data.forEach(msg => {
          message.error(msg);
        });
      } else if (typeof error.data === 'object') {
        Object.keys(error.data).forEach(key => {
          const value = error.data[key];
          if (Array.isArray(value)) {
            value.forEach(msg => {
              message.error(` ${msg}`);
            });
          } else if (typeof value === 'string') {
            message.error(`${value}`);
          } else if (typeof value === 'object') {
            Object.keys(value).forEach(nestedKey => {
              message.error(`${value[nestedKey]}`);
            });
          }
        });
      } else {
        message.error('Unknown error format');
      }
    } else {
      message.error('No error data available');
    }
  };