import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_INPUT,
  HIDE_MESSAGE,
  HIDE_MODAL,
  SHOW_INPUT,
  SHOW_MESSAGE,
  SHOW_MODAL, 
  HANDLE_ERROR_RESPONSE,
  SHOW_CONFETTI,
  HIDE_CONFETTI,
  HANDLE_DISABLE_SUBMIT_BUTTON,
  HANDLE_REMOVE_DISABLE_SUBMIT_BUTTON
} from "../../constants/ActionTypes";

export const fetchStart = () => {
  return {
    type: FETCH_START
  }
};

export const fetchSuccess = () => {
  return {
    type: FETCH_SUCCESS
  }
};

export const fetchError = (error) => {
  return {
    type: FETCH_ERROR,
    payload: error
  }
};

export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  }
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  }
};

export const hideModal = () => {
  return {
    type: HIDE_MODAL
  }
};

export const showModal = () => {
  return {
    type: SHOW_MODAL
  }
};

export const showInput = () => {
  return {
    type: SHOW_INPUT
  }
};

export const hideInput = () => {
  return {
    type: HIDE_INPUT
  }
};
export const handleErrorResponse = (error)=>{
  return{
    type: HANDLE_ERROR_RESPONSE,
    payload: error
  }
}
export const showConfetti = () => {
  return {
    type: SHOW_CONFETTI
  }
};
export const hideConfetti = () => {
  return {
    type: HIDE_CONFETTI
  }
};

export const handleDisableSubmitButton = (formKey, disabled) => ({
  type: HANDLE_DISABLE_SUBMIT_BUTTON,
  payload: { formKey, disabled },
});

export const handleRemoveDisableSubmitButton = (formKey) => ({
  type: HANDLE_REMOVE_DISABLE_SUBMIT_BUTTON,
  payload: { formKey },
});



