import {
  ADD_SUBMISSION_SUCCESS,
  EDIT_SUBMISSION_SUCCESS,
  FETCH_ALL_SUBMISSIONS_SUCCESS,
  FETCH_ASSESSMENT_FAILED,
  FETCH_ASSESSMENT_LIST_FAILED,
  FETCH_ASSESSMENT_LIST_SUCCESS,
  FETCH_ASSESSMENT_SUCCESS,
  FETCH_PENDING_SUBMISSIONS_SUCCESS,
  FETCH_SUBMISSION_ATTACHMENTS_SUCCESS,
  FETCH_SUBMISSION_SUCCESS,
  FETCH_SUBMISSIONS_SUCCESS,
  FETCH_TEAM_ASSESSMENTS_FAILED,
  FETCH_TEAM_ASSESSMENTS_SUCCESS,
  FETCH_TEAM_SUBMISSIONS_SUCCESS,
  SELECTED_ASSESSMEN_TYPE,
  SELECTED_SUBMISSION,
  SUBMISSION_INIT
} from "../../constants/ActionTypes";


const INIT_STATE = {
  submission: null,
  totalSubmissions: 0,
  totalPending: 0,
  totalTeam: 0,
  submissions: null,
  pendingSubmissions: null,
  teamSubmissions: null,
  allSubmissions: null, // to get all submissions list without pagination used for offers page
  attachments: null,
  assessments: null,
  assessmentList: null,
  teamAssessments: null,
  totalTeamAssessment:0,
  totalAssessments:0,
  selectedSubmissionId: null,
  selectedAssessmentType: null
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SUBMISSION_INIT:{
      return {
        ...state,
        submission: null
      }
    }
    case FETCH_SUBMISSIONS_SUCCESS: {
      return {
        ...state,
        submissions: action.payload.results,
        totalSubmissions: action.payload.count,
      }
    }
    case FETCH_PENDING_SUBMISSIONS_SUCCESS: {
      return {
        ...state,
        pendingSubmissions: action.payload.results,
        totalPending: action.payload.count,
      }
    }
    case FETCH_TEAM_SUBMISSIONS_SUCCESS: {
      return {
        ...state,
        teamSubmissions: action.payload.results,
        totalTeam: action.payload.count,
      }
    }
    case ADD_SUBMISSION_SUCCESS: {
      return {
        ...state,
        submission: action.payload
      }
    }
    case EDIT_SUBMISSION_SUCCESS: {
      return {
        ...state,
        submission: action.payload
      }
    }
    case FETCH_SUBMISSION_SUCCESS: {
      return {
        ...state,
        submission: action.payload
      }
    }
    case FETCH_ALL_SUBMISSIONS_SUCCESS: {
      return {
        ...state,
        allSubmissions: action.payload.results
      }
    }
    case FETCH_ASSESSMENT_SUCCESS: {
      return {
        ...state,
        assessments: action.payload
      }
    }
    case FETCH_ASSESSMENT_FAILED: {
      return {
        ...state,
        assessments: null
      }
    }
    case FETCH_ASSESSMENT_LIST_SUCCESS: {
      return {
        ...state,
        assessmentList: action.payload.results,
        totalAssessments: action.payload.count
      }
    }
    case FETCH_ASSESSMENT_LIST_FAILED: {
      return {
        ...state,
        assessmentList: null,
        totalAssessments:0
      }
    }
    case FETCH_TEAM_ASSESSMENTS_SUCCESS: {
      return {
        ...state,
        teamAssessments: action.payload.results,
        totalTeamAssessment: action.payload.count
      }
    }
    case FETCH_TEAM_ASSESSMENTS_FAILED: {
      return {
        ...state,
        teamAssessments: null,
        totalTeamAssessment:0
      }
    }
    case FETCH_SUBMISSION_ATTACHMENTS_SUCCESS: {
      return {
        ...state,
        attachments: action.payload
      }
    }
    case SELECTED_SUBMISSION: {
      return {
        ...state,
        selectedSubmissionId: action.payload
      }
    }
    // AssessmentType
    case SELECTED_ASSESSMEN_TYPE: {
      return {
        ...state,
        selectedAssessmentType: action.payload
      }
    }
     default:
      return state;
  }
};
