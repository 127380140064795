import React from "react";
import {Redirect, Switch} from "react-router";
import {PropsRoute} from "../../util/routeRestriction";
import asyncComponent from "../../util/asyncComponent";

const AsyncCalendarView = asyncComponent(() => import('./view'))

const UserCalendar = (props) => (

  <Switch>
    <Redirect exact from={`${props.match.url}/calendar`} to={`${props.match.url}`}/>
     <PropsRoute
      path={`${props.match.url}/view`}
      user={props.user}
      dispatch={props.dispatch}
      component={AsyncCalendarView}/>
  </Switch>

);

export default UserCalendar;
