import React from 'react'
import TransferRequest from '../../components/consultant/Table/TransferRequest'

const index = ({user}) => {
  return (
    <div>
      <TransferRequest user={user}/>
    </div>
  )
}

export default index
