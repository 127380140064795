
import {fetchError, fetchStart,handleErrorResponse, fetchSuccess, showMessage} from "./Common";
import {userService} from "../services";
import {FETCH_ALL_MARKETER_DASHBOARD_ANALYTICS, FETCH_ALL_OFFERS_COUNT, FETCH_NOTIFICATIONS_FAILURE, FETCH_NOTIFICATIONS_SUCCESS ,FETCH_ALL_COUNT_TO_STATUS_MARKETING ,FETCH_ASSIGNED_CONSULTANT ,FETCH_PNL_OFFERS, READ_NOTIFICATIONS_SUCCESS, READ_NOTIFICATIONS_FAILURE, FETCH_TOTAL_ONGING_OFFERS ,FETCH_RECRUITERS_CONSULTANT_CONST ,UPDATE_START_DATE_CONSULTANT_COUNT_TABLE ,UPDATE_END_DATE_CONSULTANT_COUNT_TABLE ,UPDATE_START_DATE_OFFERS_FOR_MONTH_TABLE ,UPDATE_END_DATE_OFFERS_FOR_MONTH_TABLE ,FETCH_PROJECT_TYPE_PNL_DATA, FETCH_TOTAL_DATA_MARKETING_PROFILE_TOTAL, FETCH_ASSIGNED_CONSULTANT_DATA_BY_GROUP, FETCH_MONTHWISE_SUBMISSION_AND_ASSESSMENT_SUCCESS, FETCH_CURRENT_WEEK_OFFERS_LIST_SUCCESS} from "../../constants/ActionTypes";
import {GET_NOTIFICATIONS, TRANSFER_REQUEST, PRACTICES_STATUS, READ_NOTIFICATIONS, ASK_CONSULTANT_ONBOARDING, ASK_CONSULTANT_BGC, GET_OFFER_RATIO, GET_MARKETERS_ANALYTICS ,GET_MARKETING_STATUS_COUNT ,GET_ASSIGNED_CONSULTANT_DATA ,GET_MARKETER_PNL_OFFERS, GET_ONGOING_MARKETING_OFFERS ,GET_CONSULTANT_COUNT_OF_RECRUITER ,GET_PROJECT_TYPE_TABLE_DATA, GET_TOTAL_MARKETING_PROFILE_TABLE_DATA, GET_ASSIGNED_CONSULTANT_BY_GROUP_TABLE_DATA, GET_MONTHWISE_SUBMISSION_AND_ASSESSMENT_DATA, GET_CURRENT_WEEK_OFFERS_LIST} from "../../constants/ApiEndpoints";

export const fetchNotificationSuccess = (response) => {
    return {
      type: FETCH_NOTIFICATIONS_SUCCESS,
      payload: response
    }
  };
  
  export const fetchNotificationFailure = () => {
    return {
      type: FETCH_NOTIFICATIONS_FAILURE
    }
  };
  
  export const fetchNotification = (page=1) => {

    var end_point = GET_NOTIFICATIONS.replace('pageNumber', page);
    return (dispatch) => {
      dispatch(fetchStart());
      userService.get(end_point).then((response) => {
        if (response) {
          dispatch(fetchNotificationSuccess(response));
          dispatch(fetchSuccess());
        }
      }).catch(function (error) {
        dispatch(fetchNotificationFailure());
        console.log("Error Notification Fetch****:", error);
      });
    }
  };

//   read notifications
export const readNotificationSuccess = (response) => {
  return {
    type: READ_NOTIFICATIONS_SUCCESS,
    payload: response.unread_notification_count
  }
};
export const readNotificationFailure = () => {
  return {
    type: READ_NOTIFICATIONS_FAILURE
  }
};

export const readNotification = (arrViewed) => {
    const objViewed = {
        "notification_ids": arrViewed
    }
    return (dispatch) => {
      dispatch(fetchStart());
      userService.patch(READ_NOTIFICATIONS, objViewed).then((response) => {
        if (response) {
          dispatch(readNotificationSuccess(response));
          dispatch(fetchSuccess());
        }
      }).catch(function (error) {
        console.log("Error Notification Read****:", error);
        dispatch(readNotificationFailure());
      });
    }
  };


//   handle Approve/ Reject Transfer Request


export const handleTransferRequest = (objAction) => {
    return (dispatch) => {
      dispatch(fetchStart());
      userService.post(TRANSFER_REQUEST, objAction).then((response) => {
        if (response) {
          dispatch(showMessage("Success: Transfer Action complete."));
          dispatch(fetchSuccess());
          dispatch(fetchNotification());
        }
      }).catch(function (error) {
        dispatch(fetchError("Error: Transfer Action Failed!"));
        console.log("Error Notification Fetch****:", error);
      });
    }
  };

//  handle Approve/Reject Practices

export const handlePracticesStatus = (objAction, consId, pracId) => {
    var end_point = PRACTICES_STATUS.replace('consId', consId);
    end_point = end_point.replace('pracId', pracId);
    return (dispatch) => {
      dispatch(fetchStart());
      userService.patch(end_point, objAction).then((response) => {
        if (response) {
          dispatch(showMessage("Success: Practices Action complete."));
          dispatch(fetchSuccess());
          dispatch(fetchNotification());
        }
      }).catch(function (error) {
        dispatch(fetchError("Error: Practices Action Failed!"));
        console.log("Error Notification Fetch****:", error);
      });
    }
  };

// handle send consultant onboarding

export const handleAskOnboarding = (slug ,filterBY={email:null}) => {
  var end_point = ASK_CONSULTANT_ONBOARDING.replace('con_slug', slug);
  return (dispatch) => {
    dispatch(fetchStart());
    userService.post(end_point ,filterBY).then((response) => {
      if (response) {
        dispatch(showMessage("Success: Onboarding Form sent!"));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
    });
  }
};

// handle send consultant bgc
export const handleAskBGC = (slug ,filterBY={email:null}) => {
  var end_point = ASK_CONSULTANT_BGC.replace('conSlug', slug);
  return (dispatch) => {
    dispatch(fetchStart());
    userService.post(end_point ,filterBY).then((response) => {
      if (response) {
        dispatch(showMessage("Success: Bgc Form sent!"));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
    });
  }
};

export const fetchAllOffers = (response) => {
  return {
    type: FETCH_ALL_OFFERS_COUNT,
    payload: response
  }
}

export const fetchMarketerDashboardAnalysis = (response) => {
  return {
    type: FETCH_ALL_MARKETER_DASHBOARD_ANALYTICS,
    payload: response
  }
}

export const fetchMarketingStatusCount = (response) =>  {
  return {
    type: FETCH_ALL_COUNT_TO_STATUS_MARKETING,
    payload: response
  }
}

export const fetchAssignedConsultants = (response) => {
      return {
        type: FETCH_ASSIGNED_CONSULTANT,
        payload: response
      }
}

export const fetchMarketersPnlOffers = (response) => {
      return {
        type: FETCH_PNL_OFFERS,
        payload: response
      }
}

export const fetchFullYearOffers = (filterBY={year:null ,include_self:true}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_OFFER_RATIO,filterBY).then((response) => {
      if (response) {
        dispatch(fetchAllOffers(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
        dispatch(fetchError(error.data.error));
    });
  }
};

export const fetchAllMarketerAnalysis = (from_date = null, to_date=null ,sort_by=null ,filterBy={include_self: true ,teamlead:null}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_MARKETERS_ANALYTICS, { from_date, to_date ,sort_by ,include_self: filterBy.include_self ,teamlead: filterBy.teamlead})
      .then((response) => {
        if (response) {
          dispatch(fetchMarketerDashboardAnalysis(response));
          dispatch(fetchSuccess());
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.data.error));
      });
  };
};

export const fetchStatusMarketingCount = (filterBy={include_self : true}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_MARKETING_STATUS_COUNT,filterBy)
      .then((response) => {
        if (response) {
          dispatch(fetchMarketingStatusCount(response));
          dispatch(fetchSuccess());
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.data.error));
      });
  };
};

export const fetchAssignedConsultantsTableData = (from_date=null, to_date=null ,include_self=true) => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_ASSIGNED_CONSULTANT_DATA ,{from_date, to_date ,include_self})
      .then((response) => {
        if (response) {
          dispatch(fetchAssignedConsultants(response));
          dispatch(fetchSuccess());
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.data.error));
      });
  };
};

export const fetchPnlOffers = (from_date=null, to_date=null ,include_self=true) => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_MARKETER_PNL_OFFERS ,{from_date, to_date ,include_self})
      .then((response) => {
        if (response) {
          dispatch(fetchMarketersPnlOffers(response));
          dispatch(fetchSuccess());
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.data.error));
      });
  };
};

export const fetchMarketersOngoingOffers = (response) => {
  return {
    type: FETCH_TOTAL_ONGING_OFFERS ,
    payload: response,
  }

}

export const fetchOngoingOffers = (filterBY={include_self:true ,year:null}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_ONGOING_MARKETING_OFFERS ,filterBY)
      .then((response) => {
        if (response) {
          dispatch(fetchMarketersOngoingOffers(response));
          dispatch(fetchSuccess());
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.data.error));
      });
  }; 
}

export const fetchConsultantCount = (response) => {
    return {
      type: FETCH_RECRUITERS_CONSULTANT_CONST,
      payload: response,
    }
} 

export const fetchRecruitersConsultantCount = (from_date=null, to_date=null, status=null, include_self=true) => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_CONSULTANT_COUNT_OF_RECRUITER,{from_date ,to_date, status ,include_self})
      .then((response) => {
        if (response) {
          dispatch(fetchConsultantCount(response));
          dispatch(fetchSuccess());
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.data.error));
      });
  }; 
}

export const setRecruitersConsultantCount = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
  dispatch(fetchConsultantCount(data))
  dispatch(fetchSuccess());
  
  }
}

export const fetchProjectTypePnlData = (response) => {
  return {
    type: FETCH_PROJECT_TYPE_PNL_DATA,
    payload: response,
  }
} 

export const fetchProejctTypeByPnlTableData = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_PROJECT_TYPE_TABLE_DATA)
      .then((response) => {
        if (response) {
          dispatch(fetchProjectTypePnlData(response));
          dispatch(fetchSuccess());
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.data.error));
      });
  }; 
} 

export const fetchMarketingProfileTotalData = (response) => {
  return {
    type: FETCH_TOTAL_DATA_MARKETING_PROFILE_TOTAL,
    payload: response,
  }
} 

export const fetchTotalMarketingProfileTableData = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_TOTAL_MARKETING_PROFILE_TABLE_DATA)
      .then((response) => {
        if (response) {
          dispatch(fetchMarketingProfileTotalData(response));
          dispatch(fetchSuccess());
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.data.error));
      });
  }; 
} 

export const fetchAssignedConsultantDataByGroup = (response) => {
  return {
    type: FETCH_ASSIGNED_CONSULTANT_DATA_BY_GROUP,
    payload: response,
  }
} 

export const fetchAssignedConsultantByGroupTableData = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_ASSIGNED_CONSULTANT_BY_GROUP_TABLE_DATA)
      .then((response) => {
        if (response) {
          dispatch(fetchAssignedConsultantDataByGroup(response));
          dispatch(fetchSuccess());
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.data.error));
      });
  }; 
} 

export const monthwiseSubmissionsAndAssessmentsSuccess = (response) => {
  return {
    type: FETCH_MONTHWISE_SUBMISSION_AND_ASSESSMENT_SUCCESS,
    payload: response,
  }
} 

export const fetchMonthwiseSubmissionsAndAssessmentsData = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_MONTHWISE_SUBMISSION_AND_ASSESSMENT_DATA)
      .then((response) => {
        if (response) {
          dispatch(monthwiseSubmissionsAndAssessmentsSuccess(response));
          dispatch(fetchSuccess());
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.data.error));
      });
  }; 
} 

export const fetchCurrentWeekOffersListSuccess = (response) => {
  return {
    type: FETCH_CURRENT_WEEK_OFFERS_LIST_SUCCESS,
    payload: response,
  }
} 

export const fetchCurrentWeekOfferList = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_CURRENT_WEEK_OFFERS_LIST)
      .then((response) => {
        if (response) {
          dispatch(fetchCurrentWeekOffersListSuccess(response));
          dispatch(fetchSuccess());
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.data.error));
      });
  }; 
} 

export const updateStartingDateConsultantCountTable = (response) => {
  return {
    type: UPDATE_START_DATE_CONSULTANT_COUNT_TABLE,
    payload: response,
  }
} 

export const setConsultantCountTableStartingDate = (date) => {
      return (dispatch) => {
        dispatch(updateStartingDateConsultantCountTable(date))
      }
}

export const updateEndingDateConsultantCountTable = (response) => {
  return {
    type: UPDATE_END_DATE_CONSULTANT_COUNT_TABLE,
    payload: response,
  }
} 

export const setConsultantCountTableEndingDate = (date) => {
      return (dispatch) => {
        dispatch(updateEndingDateConsultantCountTable(date))
      }
}

//  offers for month table date updaters

export const updateStartingDateOffersForMonthTable = (response) => {
  return {
    type: UPDATE_START_DATE_OFFERS_FOR_MONTH_TABLE,
    payload: response,
  }
}

export const  setOffersForMonthTableStartingDate = (date) => {
    return (dispatch) => {
      dispatch(updateStartingDateOffersForMonthTable(date))
    }
}

export const updateEndingDateOffersForMonthTable = (response) => {
  return {
    type: UPDATE_END_DATE_OFFERS_FOR_MONTH_TABLE,
    payload: response,
  }
}

export const setOffersForMonthTableTableEndingDate = (date) => {
  return (dispatch) => {
    dispatch(updateEndingDateOffersForMonthTable(date))
  }
}



