import {Redirect, Route} from "react-router";
import React from "react";


export const RestrictedRoute = ({component: Component, location, authUser, dispatch, isAuthenticated, ...rest}) =>
  <Route
    {...rest}
    render={props => isAuthenticated ? <Component dispatch={dispatch} user={authUser} {...props} /> :
      <Redirect to={{pathname: '/signin', state: {from: location}}} />
    }
  />;


export const RoleRestrictedRoute = ({component: Component, dispatch, user, roles, ...rest}) =>
  <Route
    {...rest}
    render={props => user.groups.some(r => roles.indexOf(r) >= 0) ? <Component user={user} dispatch={dispatch} {...props} /> :
      <Redirect to={{ pathname: `/dashboard/username`.replace('username', user.username) }} />
    }
  />;

export const UserRestrictedRoute = ({component: Component, user, dispatch, username, location, ...rest}) =>
  <Route
    {...rest}
    render={props => username === location.pathname.split("/")[1] ? <Component dispatch={dispatch} user={user} {...props} /> :
     console.log('')
      // alert(`${username}--${location}---${location.pathname}---${location.pathname.split("/")[1]} not allowed to view this page.`)
    }
  />;

export const PropsRoute = ({component: Component, dispatch, user, ...rest}) =>
   <Route
     {...rest}
     render={ props => <Component dispatch={dispatch} user={user} {...props} /> }
   />;

// export const UnrestrictedRoute = ({component: Component}) => 
//    <Route 
//      render={props => <Component {...props} />}
//    />;

