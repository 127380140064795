import {
  ADD_EDUCATION_FAILURE,
  ADD_EDUCATION_SUCCESS,
  ADD_EXPERIENCE_FAILURE,
  ADD_EXPERIENCE_SUCCESS,
  FETCH_EDUCATION_LIST_FAILURE,
  FETCH_EDUCATION_LIST_SUCCESS,
  FETCH_EXPERIENCE_LIST_FAILURE,
  FETCH_EXPERIENCE_LIST_SUCCESS,
  FETCH_USER_EDUCATION_FAILURE,
  FETCH_USER_EDUCATION_SUCCESS,
  FETCH_USER_EXPERIENCE_FAILURE,
  FETCH_USER_EXPERIENCE_SUCCESS,
  FETCH_USER_FAILURE,
  FETCH_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  ADD_ADDRESS_FAILURE,
  ADD_ADDRESS_SUCCESS,
  FETCH_ADDRESS_LIST_FAILURE,
  FETCH_ADDRESS_LIST_SUCCESS,
  FETCH_USER_ADDRESS_FAILURE,
  FETCH_USER_ADDRESS_SUCCESS,
  ADD_PRACTICE_FAILURE,
  ADD_PRACTICE_SUCCESS,
  FETCH_PRACTICE_LIST_FAILURE,
  FETCH_PRACTICE_LIST_SUCCESS,
  FETCH_USER_PRACTICE_FAILURE,
  FETCH_USER_PRACTICE_SUCCESS,
  FETCH_PREVIOUS_CLIENT_LIST_SUCCESS,
} from "../../constants/ActionTypes";


const INIT_STATE = {
  // loggedInUser: null,
  educationList: null,
  experienceList: null,
  addressList: null,
  practiceList: null,
  education: '',
  experience: '',
  address:'',
  practice:'',
  previousClientList:[],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //  case USER_INIT:{
    //   return {
    //     educationList: null,
    //     experienceList: null
    //   }
    // }
    case FETCH_USER_SUCCESS: {
      sessionStorage.setItem('user', JSON.stringify(action.payload));
      return {
        ...state,
        loggedInUser: action.payload
      }
    }
    case FETCH_USER_FAILURE: {
      return {
        ...state,
      }
    }
    
    case FETCH_USER_EXPERIENCE_SUCCESS:{
      return {
        ...state,
        experience: action.payload
      }
    }
    case FETCH_USER_EDUCATION_SUCCESS:{
      return {
        ...state,
        education: action.payload
      }
    }
    case FETCH_EDUCATION_LIST_SUCCESS: {
      return {
        ...state,
        educationList: action.payload
      }
    }
    case ADD_EDUCATION_SUCCESS: {
      return {
        ...state,
        education: action.payload
      }
    }
    case ADD_EDUCATION_FAILURE: {
      return {
        ...state
      }
    }
    case FETCH_EDUCATION_LIST_FAILURE: {
      return {
        ...state,
        educationList: null
      }
    }
    case FETCH_USER_EDUCATION_FAILURE:{
    return {
        ...state,
        education: ''
      }
    }
    // address
    case FETCH_USER_ADDRESS_SUCCESS:{
      return {
        ...state,
        address: action.payload
      }
    }
    case FETCH_ADDRESS_LIST_SUCCESS: {
      return {
        ...state,
        addressList: action.payload
      }
    }
    case ADD_ADDRESS_SUCCESS: {
      return {
        ...state,
        address: action.payload
      }
    }
    case ADD_ADDRESS_FAILURE: {
      return {
        ...state
      }
    }
    case FETCH_ADDRESS_LIST_FAILURE: {
      return {
        ...state,
        addressList: null
      }
    }
    case FETCH_USER_ADDRESS_FAILURE:{
    return {
        ...state,
        address: ''
      }
    }

        // practices
        case FETCH_USER_PRACTICE_SUCCESS:{
          return {
            ...state,
            practice: action.payload
          }
        }
        case FETCH_PRACTICE_LIST_SUCCESS: {
          return {
            ...state,
            practiceList: action.payload
          }
        }
        case ADD_PRACTICE_SUCCESS: {
          return {
            ...state,
            practice: action.payload
          }
        }
        case ADD_PRACTICE_FAILURE: {
          return {
            ...state
          }
        }
        case FETCH_PRACTICE_LIST_FAILURE: {
          return {
            ...state,
            practiceList: null
          }
        }
        case FETCH_USER_PRACTICE_FAILURE:{
        return {
            ...state,
            practice: ''
          }
        }
        // experience
    case FETCH_USER_EXPERIENCE_FAILURE:{
    return {
        ...state,
        experience: ''
      }
    }
    case FETCH_EXPERIENCE_LIST_SUCCESS: {
      return {
        ...state,
        experienceList: action.payload
      }
    }
    case FETCH_EXPERIENCE_LIST_FAILURE: {
      return {
        ...state,
        experienceList: null
      }
    }
    case ADD_EXPERIENCE_SUCCESS: {
      return {
        ...state,
        experienceList: action.payload
      }
    }
    case ADD_EXPERIENCE_FAILURE: {
      return {
        ...state
      }
    }
    case SIGNOUT_USER_SUCCESS:{
      return {
        ...state,
        loggedInUser: null
      }
    }

    // Previous client 
    case FETCH_PREVIOUS_CLIENT_LIST_SUCCESS: {
      return {
        ...state,
        previousClientList: action.payload
      }
    }
    default:
      return state;
  }
}
