import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER_FAILURE,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  SET_SIGNIN_ERROR
} from "../../constants/ActionTypes";
import {CREATE_USER, GET_TOKEN, LOGOUT, RESET_PASSWORD} from "../../constants/ApiEndpoints";

import { axiosPublic, axiosAPI } from '../../util/Api';
import { message } from 'antd';

import {authService, userService} from "../services";
import {fetchError, fetchStart, fetchSuccess, showMessage} from "./Common";
import {getUser} from "./Users";

export const userSignUp = ({email, password, name}) => {

  return (dispatch) => {
    dispatch(fetchStart());
    axiosAPI.post(CREATE_USER, {
        email: email,
        password: password,
        name: name
      }
    ).then(({data}) => {
      if (data.result) {
        sessionStorage.setItem("token", JSON.stringify(data.token));
        dispatch(fetchSuccess());
        dispatch(userSignUpSuccess(data));
      } else {
        console.log("payload: data.error", data.error);
        dispatch(fetchError("Network Error"));
      }
    }).catch(function (error) {
      dispatch(fetchError(error.message));
      console.log("Error****:", error.message);
    });
  }
};

export const userSignIn = ({username, password}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    let payload = {
           username: username,
           password: password
       };
    authService.obtainToken(GET_TOKEN, payload)
       .then((response) => {
        
        if ('error' in response ){
          dispatch(fetchError(response.error));
          dispatch(userSignInFailure());
         }
         else{
             dispatch(userSignInSuccess(response));
             dispatch(getUser());
             dispatch(fetchSuccess());
         }

      }).catch((error)=>{
        dispatch(userSignInFailure());

        for (let key in error) {
        const errorMsg = key.toUpperCase().concat(": ").concat(error[key]);

        dispatch(fetchError(errorMsg));
      }
    })
  }
};

export const setSignInError = () => {
  return {
    type:SET_SIGNIN_ERROR
  }
};

export const userSignOut = () => {

  return (dispatch) => {
    dispatch(fetchStart());
    userService.post(LOGOUT, {}).then((response) => {
      if (response.detail === 'Successfully logged out.') {
        sessionStorage.clear();
        localStorage.clear();
        dispatch(fetchSuccess());
        dispatch(userSignOutSuccess());
      } else {
        dispatch(fetchError(response.detail));
      }
    }).catch(function (error) {
      dispatch(fetchError(error.message));
      console.log("Error****:", error.message);
    });
  }
};
export const userSignUpSuccess = (responseData) => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: responseData
  };
};

export const userSignInSuccess = (responseData) => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: responseData
  }
};

export const userSignInFailure = () => {
  return {
    type: SIGNIN_USER_FAILURE
  }
};
export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  }
};

export const hasRole = (user, roles) => {
  if (user) {
    return roles.some(role => user.groups.includes(role));
  }
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

export const setInitUrl = (url) => {
  console.log(url, "url when initUrl was blank from useEffect.");
  return {
    type: INIT_URL,
    payload: url
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};


export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};

// RESET PASSWORD
export const resetPassword = (value) => {

  return (dispatch) => {
    dispatch(fetchStart());
    axiosPublic.post(RESET_PASSWORD, value)
    .then((response) => {
      message.success(response.data.message);
      dispatch(showAuthMessage(response.data.message));
    }).catch(function (error) {
      message.error(error.response.data.error);
    });
  }
};

export const resetPasswordMail = (endPoint, value) => {

  return (dispatch) => {
    dispatch(fetchStart());
    axiosPublic.post(endPoint, value)
    .then((response) => {
      message.success(response.data.message);
    }).catch(function (error) {
      console.log("Error****:", error);
      message.error(error.response.data.error);
    });
  }
};
