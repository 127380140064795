import React from "react"
import { Select } from "antd"
import { PaginationPageLength } from "../../../../constants/FilterData"

export const DynamicPageListLength = ({page_Size, handlePageSizeChange}) => {
    return <Select
            style={{ marginLeft: '10px' , transform: 'translateY(-23%)' }}
            value={page_Size}
            onChange={handlePageSizeChange}
            >
            {PaginationPageLength.map(option => (
            <Select.Option key={option.handle} value={option.handle}>
                {option.title}
            </Select.Option>
            ))}
            </Select>
} 