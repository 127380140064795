import React from "react";
import {Redirect, Switch} from "react-router";

import asyncComponent from "../../util/asyncComponent";
import {PropsRoute} from "../../util/routeRestriction"

const AsyncProfileComp = asyncComponent(() => import('./profile/view'));
const AsyncProfileViewComp = asyncComponent(() => import('./profile/edit'));
const Profile = (props) => (
  <Switch>
    <Redirect exact from={`${props.match.url}/profile`} to={`${props.match.url}`}/>
    <PropsRoute
      path={`${props.match.url}/view`}
      user={props.user}
      dispatch={props.dispatch}
      component={AsyncProfileComp}/>
    <PropsRoute
      path={`${props.match.url}/edit`}
      user={props.user}
      dispatch={props.dispatch}
      component={AsyncProfileViewComp}/>
    {/*<PropsRoute*/}
    {/*  path={`${props.match.url}/education/edit/:educationId`}*/}
    {/*  user={props.user} component={asyncComponent(() => import('./education/edit'))}/>*/}
    {/*<PropsRoute*/}
    {/*  path={`${props.match.url}/experience/edit/:experienceId`}*/}
    {/*  dispatch={props.dispatch}*/}
    {/*  user={props.user} component={asyncComponent(() => import('./experience'))}/>*/}
  </Switch>
);

export default Profile;
