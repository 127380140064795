import React from "react";
import {Redirect, Switch} from "react-router";
import asyncComponent from "../../util/asyncComponent";
import {PropsRoute} from "../../util/routeRestriction";

const AsyncRecruiterStandup = asyncComponent(() => import('./standup/index'));
const AsyncConsultant = asyncComponent(() => import('./consultant'));
const AsyncEditConsultant = asyncComponent(() => import('./consultant/edit'));
const AsyncScreening = asyncComponent(() => import('./screening'));
const AsyncScreeningInvite = asyncComponent(() => import('./screening/invite'));
const AsyncRecruiter = asyncComponent(() => import('./recruiter'));
const AsyncRecruiterEdit = asyncComponent(() => import('./recruiter/edit'));

const Recruitment = (props) => (

  <Switch>
    <Redirect exact from={`${props.match.url}/recruitment`} to={`${props.match.url}`}/>
    <PropsRoute path={`${props.match.url}/standup`} dispatch={props.dispatch}
                component={AsyncRecruiterStandup} user={props.user}/>
    <PropsRoute path={`${props.match.url}/consultant/list`} dispatch={props.dispatch}
                component={AsyncConsultant} user={props.user}/>
    <PropsRoute path={`${props.match.url}/consultant/edit/:consultantId`} dispatch={props.dispatch}
                component={AsyncEditConsultant} user={props.user}/>
    <PropsRoute path={`${props.match.url}/screening/list`} dispatch={props.dispatch}
                component={AsyncScreening} user={props.user}/>
    <PropsRoute path={`${props.match.url}/screening/invite/:screener`} dispatch={props.dispatch}
                component={AsyncScreeningInvite} user={props.user}/>
    <PropsRoute path={`${props.match.url}/recruiter/list`} dispatch={props.dispatch}
                component={AsyncRecruiter} user={props.user}/>
    <PropsRoute path={`${props.match.url}/recruiter/edit/:recruiterId`} dispatch={props.dispatch}
                component={AsyncRecruiterEdit} user={props.user}/>
  </Switch>
);

export default Recruitment;
