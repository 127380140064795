import React, {useEffect, useState} from "react";
import { Input, Form, DatePicker, Divider, Col, Radio, Upload, message, Row } from "antd";
import InboxOutlined from "@ant-design/icons/lib/icons/InboxOutlined";

function Page3({ formData, setFormData, form }) {
    const { Dragger } = Upload;
    // const [form] = Form.useForm();
    const acceptableFileTypes = ['jpg', 'jpeg', 'png', 'gif', 'pdf'];
    const filterFilesByType = (fileList) => {
      return fileList.map(file => {
          const fileExtension = file.name.split('.').pop().toLowerCase();
          const isAcceptableFileType = acceptableFileTypes.includes(fileExtension);
  
          if (!isAcceptableFileType) {
              message.error(`Unsupported file type: ${file.name}`);
              return null;
          }
  
          return file;
      }).filter(Boolean); 
  };
   const selectedVisa = formData && formData.visa && formData.visa.replace(/ /g, '_')+"_copy"

    const [fileList, setFileList] = useState([]);

    useEffect(()=>{
        if(formData && formData.gc_ead_copy && formData.gc_ead_copy.fileList){
            setFileList(formData.gc_ead_copy.fileList )
        }
        if(formData && formData.gc_copy && formData.gc_copy.fileList && formData.visa !== "GC EAD" ){
            setFileList(formData.gc_copy.fileList )
        }
        if(formData && formData.gc_copy && formData.gc_copy.fileList && formData.visa === "GC EAD" ){
            if (formData.gc_copy) delete formData.gc_copy;
            localStorage.setItem('formData', JSON.stringify({ ...formData }));
        }
        if(formData && formData.h4_ead_copy && formData.h4_ead_copy.fileList){
            setFileList(formData.h4_ead_copy.fileList )
        }
        if(formData && formData.opt_copy && formData.opt_copy.fileList){
            setFileList(formData.opt_copy.fileList )
        }
        if(formData && formData.cpt_copy && formData.cpt_copy.fileList){
            setFileList(formData.cpt_copy.fileList )
        }
        if(formData && formData.other_copy && formData.other_copy.fileList){
            setFileList(formData.other_copy.fileList )
        }
    },[])

    const handleFileChange = (info) => {
        let updatedFileList = [...info.fileList];

        updatedFileList = updatedFileList.filter((file) => {
            if (file.status === 'error') {
                message.error(`${file.name} file upload failed.`);
            }
            return file.status !== 'error';
        });
        updatedFileList = filterFilesByType(updatedFileList);
        setFileList(updatedFileList);
    };
    const handleBeforeUpload = (file) => {
        return false;
    };
     
    // const [fileListGCEAD, setFileListGCEAD] = useState([]);
    const [fileListW2, setFileListW2] = useState(formData.w2_contract?formData.w2_contract.fileList:[]);
    const [fileListPassport, setFileListPassport] = useState(formData.us_passport_copy?formData.us_passport_copy.fileList:[]);

    // const handleFileChangeGCEAD = (info) => {
    //     let updatedFileList = [...info.fileList];
    //     updatedFileList = updatedFileList.filter((file) => {
    //         if (file.status === 'error') {
    //             message.error(`${file.name} file upload failed.`);
    //         }
    //         return file.status !== 'error';
    //     });
    //     setFileListGCEAD(updatedFileList);
    // };
    const handleFileChangeW2 = (info) => {
        let updatedFileList = [...info.fileList];
        updatedFileList = updatedFileList.filter((file) => {
            if (file.status === 'error') {
                message.error(`${file.name} file upload failed.`);
            }
            return file.status !== 'error';
        });
        updatedFileList = filterFilesByType(updatedFileList);
        setFileListW2(updatedFileList);
    };


    const handleFileChangePassport = (info) => {
        let updatedFileList = [...info.fileList];
        updatedFileList = updatedFileList.filter((file) => {
            if (file.status === 'error') {
                message.error(`${file.name} file upload failed.`);
            }
            return file.status !== 'error';
        });
        updatedFileList = filterFilesByType(updatedFileList);
        setFileListPassport(updatedFileList);
    };


    const uploadProps = {
        multiple: false,
        fileList,
        beforeUpload: handleBeforeUpload,
        onChange: handleFileChange,
    };

  return (
    <div>
        <Form
          layout="inline"
          form={form}
          initialValues={formData}
          onValuesChange={(changedValues, allValues) => {
            setFormData({ ...formData, ...allValues });
            }}
          className="gx-modal-box-form-item">
             <div style={{width:'100%'}}>
            <div style={{width:"100%",textAlign:"center",fontWeight:"bold",fontSize:"15px"}}>{formData.visa} Copy
            </div>
            <Divider/>
            <Row>
           {formData && formData.visa && formData.visa !=="USC" && <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label={`Attach ${formData.visa} Copy`} name={selectedVisa.toLowerCase()}
              rules={[{
                required: true,
                message: 'Please upload document!'
              }]}>
                <Dragger {...uploadProps} fileList={fileList} onChange={handleFileChange}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag files to this area to upload</p>
                </Dragger>
              </Form.Item>
              </Col>}

              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label="Attach Passport" name='us_passport_copy'  
                    >
                    <Dragger {...uploadProps} fileList={fileListPassport} onChange={handleFileChangePassport}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    </Dragger>
                </Form.Item>
            </Col>
            </Row>
              </div>
              {/* <div style={{width:"100%",textAlign:"center",fontWeight:"bold",fontSize:"15px"}}>GC/EAD</div>
            <Divider/>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label="Work Permit (GC Copy/EAD Copy/H1B)" name='work_permit'  rules={[{
                        required: true,
                        message: 'Please upload work permit doc!'
                    }]}>
                    <Dragger {...uploadProps} fileList={fileListGCEAD} onChange={handleFileChangeGCEAD}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    </Dragger>
                </Form.Item>
            </Col> */}

           
            <Divider/>
            <div style={{width:"100%",textAlign:"center",fontWeight:"bold",fontSize:"15px"}}>W2 Contract with American Chase</div>
            <Divider/>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item label="Attach W-2 Contract With American Chase" name={'w2_contract'}  rules={[{
                        required: true,
                        message: 'Please upload contract document!'
                    }]}>
                    <Dragger {...uploadProps} fileList={fileListW2} onChange={handleFileChangeW2}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    </Dragger>
                </Form.Item>
             </Col>

            <Divider />
            <Col span={2}>
                          
            </Col>
        </Form>
    </div>
  );
}

export default Page3;
