import React, {useEffect, useState} from "react";
import {Layout, Popover, Badge} from "antd";
import {Link} from "react-router-dom";
// import CustomScrollbars from "util/CustomScrollbars";
// import languageData from "./languageData";
import {toggleCollapsedSideNav} from "../../appRedux/actions/Setting";
import SearchBox from "../../components/Extras/SearchBox";

import AppNotification from "../../components/AppNotification";
import MailNotification from "../../components/MailNotification";
import Auxiliary from "../../util/Auxiliary";


import {NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE} from "../../constants/ThemeSetting";
import {useDispatch, useSelector} from "react-redux";
import UserInfo from "../../components/Extras/UserInfo";
import BreadCrumb from "../../components/Extras/BreadCrumbs";
import CalendarOutlined from "@ant-design/icons/lib/icons/CalendarOutlined";
import {fetchCalendar} from "appRedux/actions";
import { fetchNotification } from "../../appRedux/actions";
import UserProfile from "../Sidebar/UserProfile";

const {Header} = Layout;

const Topbar = ({user, location}) => {

  const {width, navCollapsed, navStyle} = useSelector(({settings}) => settings);
  const {searchText, setSearchText} = useState('');
  const [handleNotificationPopoverOpen, setHandleNotificationPopoverOpen] = useState(false);
  const dispatch = useDispatch();
  
  const notificationCount =  useSelector((state) => state.essentials.notificationCount);

  useEffect(() => {
    dispatch(fetchNotification());
    const intervalId = setInterval(() => {
      dispatch(fetchNotification());
    }, 180000);
     return () => clearInterval(intervalId);
  }, []);
  // const languageMenu = () => (
  //   <CustomScrollbars className="gx-popover-lang-scroll">
  //     <ul className="gx-sub-popover">
  //       {languageData.map(language =>
  //         <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={(e) =>
  //           dispatch(switchLanguage(language))
  //         }>
  //           <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`}/>
  //           <span className="gx-language-text">{language.name}</span>
  //         </li>
  //       )}
  //     </ul>
  //   </CustomScrollbars>);

  const updateSearchChatUser = (evt) => {
    setSearchText(evt.target.value);
  };

  const handlePopoverToggle = () => {
    if(!handleNotificationPopoverOpen){
      setHandleNotificationPopoverOpen(true)
    }else{
      setHandleNotificationPopoverOpen(false)
    }
  };

  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ?
        <div className="gx-linebar gx-mr-3">
          <i className="gx-icon-btn icon icon-menu"
             onClick={() => {
               dispatch(toggleCollapsedSideNav(!navCollapsed));
             }}
          />
        </div> : null}
      {/*<Link to={`/${user.username}/dashboard`} className="gx-d-block gx-d-lg-none gx-pointer">*/}
      {/*  <img alt="" src={require("assets/images/w-logo.png")}/></Link>*/}

      <BreadCrumb user={user} location={location}/>
      {/* <SearchBox styleName="gx-d-none gx-d-lg-block gx-lt-icon-search-bar-lg"*/}
      {/*           placeholder="Search in app..."*/}
      {/*           onChange={updateSearchChatUser}*/}
      {/*           value={searchText}/> */}
      <ul className="gx-header-notifications gx-ml-auto">
        
        <li className="gx-msg">
              <Popover overlayClassName="gx-popover-horizantal" open={handleNotificationPopoverOpen}  placement="bottomRight"
                       content={<MailNotification user={user} setHandleNotificationPopoverOpen={setHandleNotificationPopoverOpen} />} trigger="click">
                    
                  {/* <span className="gx-pointer gx-status-pos gx-d-block"> */}
                        
                  <i className="icon icon-notification" onClick={handlePopoverToggle}/>
                   {/* {notificationsData && notificationsData.unread >0 && <span className="gx-status gx-status-rtl gx-small gx-orange"/>} */}
                 
              <Badge  count={notificationCount?notificationCount:0} size="small" offset={[-10, -20]}>  
              </Badge>
                 
                  {/* </span> */}
                 
              </Popover>
            </li>
        <li>
          <Link to={`/${user.username}/calendar/view`} onClick={()=>{dispatch(fetchCalendar({screener_email: user.work_email, start: null, end: null}))}}>
        <span className="gx-pointer gx-d-block"><CalendarOutlined/></span>
        </Link>
        </li>
        <div style={{display:"flex" ,alignItems:"center" ,alignContent:"center" ,justifyContent:"center" ,transform:"translate(0px ,14px)"}}>
            <UserProfile user={user}/>
        </div>
    
        {width >= TAB_SIZE ? null :
          <Auxiliary>
            <div></div>
            {/* <li className="gx-notify">
              <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={<AppNotification/>}
                       trigger="click">
                <span className="gx-pointer gx-d-block"><i className="icon icon-notification"/></span>
              </Popover>
            </li> */}

            {/* <li className="gx-msg">
              <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight"
                       content={<MailNotification/>} trigger="click">
                  <span className="gx-pointer gx-status-pos gx-d-block">
                    <i className="icon icon-chat-new"/>
                    <span className="gx-status gx-status-rtl gx-small gx-orange"/>
                  </span>
              </Popover>
            </li> */}
          </Auxiliary>
        }

        {/*<li className="gx-language">*/}
        {/*  <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={languageMenu()}*/}
        {/*           trigger="click">*/}
        {/*        <span className="gx-pointer gx-flex-row gx-align-items-center">*/}
        {/*          <i className={`flag flag-24 flag-${locale.icon}`}/>*/}
        {/*          <span className="gx-pl-2 gx-language-name">{locale.name}</span>*/}
        {/*          <i className="icon icon-chevron-down gx-pl-2"/>*/}
        {/*        </span>*/}
        {/*  </Popover>*/}
        {/*</li>*/}
        {width >= TAB_SIZE ? null :
          <Auxiliary>
            <li className="gx-user-nav"><UserInfo/></li>
          </Auxiliary>
        }
      </ul>
      {handleNotificationPopoverOpen && <div style={{
              background: "transparent",
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              zIndex: 999,
            }} onClick={() => setHandleNotificationPopoverOpen(false)}></div>}
    </Header>
  );
};

export default Topbar;
