import {
  ADD_SUBMISSION_FAILED,
  ADD_SUBMISSION_SUCCESS,
  DELETE_ASSESSMENT_FAILURE,
  DELETE_ASSESSMENT_SUCCESS,
  EDIT_ASSESSMENT_FAILED,
  EDIT_ASSESSMENT_SUCCESS,
  EDIT_SUBMISSION_FAILED,
  EDIT_SUBMISSION_SUCCESS,
  FETCH_ALL_SUBMISSIONS_FAILED,
  FETCH_ALL_SUBMISSIONS_SUCCESS,
  FETCH_ASSESSMENT_FAILED,
  FETCH_ASSESSMENT_LIST_FAILED,
  FETCH_ASSESSMENT_LIST_SUCCESS,
  FETCH_ASSESSMENT_SUCCESS,
  FETCH_PENDING_SUBMISSIONS_FAILED,
  FETCH_PENDING_SUBMISSIONS_SUCCESS,
  FETCH_SUBMISSION_ATTACHMENTS_FAILURE,
  FETCH_SUBMISSION_ATTACHMENTS_SUCCESS,
  FETCH_SUBMISSION_FAILED,
  FETCH_SUBMISSION_SUCCESS,
  FETCH_SUBMISSIONS_FAILED,
  FETCH_SUBMISSIONS_SUCCESS,
  FETCH_TEAM_ASSESSMENTS_FAILED,
  FETCH_TEAM_ASSESSMENTS_SUCCESS,
  FETCH_TEAM_SUBMISSIONS_FAILED,
  FETCH_TEAM_SUBMISSIONS_SUCCESS,
  SELECTED_ASSESSMEN_TYPE,
  SELECTED_SUBMISSION,
  SUBMISSION_INIT
} from "../../constants/ActionTypes";
import {fetchError, fetchStart, fetchSuccess, handleErrorResponse, handleRemoveDisableSubmitButton, showMessage} from "./Common";
import {userService} from "../services";
import {
  ADD_SUBMISSION,
  DELETE_ASSESSMENTS,
  DELETE_SUBMISSION,
  EDIT_ASSESSMENTS,
  EDIT_SUBMISSION,
  FETCH_SUBMISSION_ATTACHMENTS,
  GET_ALL_SUBMISSIONS,
  GET_ASSESSMENTS,
  GET_ASSESSMENTS_LIST,
  GET_MY_SUBMISSIONS,
  GET_PENDING_SUBMISSIONS,
  GET_SUBMISSION,
  GET_TEAM_ASSESSMENTS,
  GET_TEAM_SUBMISSIONS
} from "../../constants/ApiEndpoints";

export const submissionInit = () => {
  return {
    type: SUBMISSION_INIT,
  }
};


export const fetchSubmissionsSuccess = (submissions) => {
  return {
    type: FETCH_SUBMISSIONS_SUCCESS,
    payload: submissions
  }
};

export const fetchSubmissionsFailure = () => {
  return {
    type: FETCH_SUBMISSIONS_FAILED,
  }
};


export const fetchSubmissions = (filterBy={page:1, type:null, client__type:null, ordering:null, marketer: null ,from_date: null, to_date: null ,team_profiles:null ,include_self:false}) => {
  return(dispatch) => {
  dispatch(fetchStart());
  dispatch(submissionInit());
  userService.get(GET_MY_SUBMISSIONS, filterBy).then((response) => {
      if (response) {
        dispatch(fetchSubmissionsSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchSubmissionsFailure());
    });
  };
};


export const fetchPendingSubmissionsSuccess = (submissions) => {
  return {
    type: FETCH_PENDING_SUBMISSIONS_SUCCESS,
    payload: submissions
  }
};

export const fetchPendingSubmissionsFailure = () => {
  return {
    type: FETCH_PENDING_SUBMISSIONS_FAILED
  }
};


export const fetchPendingSubmissions = (filterBy={page:1, type:null, client__type:null, ordering:null}) => {
  return(dispatch) => {
  dispatch(fetchStart());
  dispatch(submissionInit());
  userService.get(GET_PENDING_SUBMISSIONS, filterBy).then((response) => {
      if (response) {
        dispatch(fetchPendingSubmissionsSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchPendingSubmissionsFailure());
    });
  };
};


export const fetchTeamSubmissionsSuccess = (submissions) => {
  return {
    type: FETCH_TEAM_SUBMISSIONS_SUCCESS,
    payload: submissions
  }
};

export const fetchTeamSubmissionsFailure = () => {
  return {
    type: FETCH_TEAM_SUBMISSIONS_FAILED
  }
};


export const fetchTeamSubmissions = (filterBy={page:1, type:null, client__type:null, ordering:null}) => {
  return(dispatch) => {
  dispatch(fetchStart());
  dispatch(submissionInit());
  userService.get(GET_TEAM_SUBMISSIONS, filterBy).then((response) => {
      if (response) {
        dispatch(fetchTeamSubmissionsSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchTeamSubmissionsFailure());
    });
  };
};

export const addSubmissionSuccess = (submission) => {
  return {
    type: ADD_SUBMISSION_SUCCESS,
    payload: submission
  }
};

export const addSubmissionFailed = () => {
  return {
    type: ADD_SUBMISSION_FAILED,
  }
};

export const addSubmission = (values) =>{
  const  headers = {
    'Content-Type': 'multipart/form-data'
  }
  return (dispatch) => {
    dispatch(fetchStart());
    dispatch(submissionInit());
    userService.post(ADD_SUBMISSION, values, headers).then((response) => {
      if(response) {
        dispatch(handleRemoveDisableSubmitButton('make_new_submission'))
        dispatch(handleErrorResponse({"addSubmission": "success"}));
        dispatch(addSubmissionSuccess(response));
        dispatch(fetchSubmissions());
        dispatch(showMessage("Added successfully!"))
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(handleRemoveDisableSubmitButton('make_new_submission'))
      dispatch(fetchError(error.data.error));
      dispatch(handleErrorResponse({"addSubmission": error}));
      dispatch(addSubmissionFailed());
      dispatch(fetchSubmissionsFailure());
    })
  }
};

export const editSubmissionSuccess = (submission) => {
  return {
    type: EDIT_SUBMISSION_SUCCESS,
    payload: submission
  }
};

export const editSubmissionFailed = () => {
  return {
    type: EDIT_SUBMISSION_FAILED,
  }
};

export const editSubmission = (values, id, team=false) =>{
  return (dispatch) => {
    dispatch(fetchStart());
    const apiEndPoint = EDIT_SUBMISSION.replace('id', id);
    userService.patch(apiEndPoint, values).then((response) => {
      if(response) {
        dispatch(handleErrorResponse({"editSubmission": "success"}));
        dispatch(showMessage(`Submission for ${response.consultant_name} to ${response.vendor.name} updated successfully.`));
        // dispatch(fetchSubmission(id));
        if(team){
          dispatch(fetchTeamSubmissions());
        }
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data?error.data.errors:"Error Editing Submissions!"));
      dispatch(editSubmissionFailed());
      dispatch(handleErrorResponse({"editSubmission": error}));
    })
  }
};

export const updateSubmissionDocuments = (values, id) => {
  const  headers = {
    'Content-Type': 'multipart/form-data'
  }
    return (dispatch) => {
        dispatch(fetchStart());
        const endPoint = EDIT_SUBMISSION.replace('id', id);
        userService.upload(endPoint, values, headers, "resume")
            .then((response) => {

              // if(response && (response.status ===200 ||response.status===201)){
                dispatch(fetchSubmissionAttachments(id));
                dispatch(fetchSuccess());
                dispatch(showMessage("Attachment Uploaded Successfully!"));
              // }else{
              //   dispatch(fetchError("Error Attaching Documents!"))
              // }
            }).catch((error) => {
              dispatch(fetchError(error?error.data?error.data.error:"Error Editing Submissions!":"Error Editing Submissions!"));
              dispatch(editSubmissionFailed());
        });
    }
};

export const fetchSubmissionSuccess = (submissions) => {
  return {
    type: FETCH_SUBMISSION_SUCCESS,
    payload: submissions
  }
};

export const fetchSubmissionFailure = () => {
  return {
    type: FETCH_SUBMISSION_FAILED,
  }
};


export const fetchSubmission = (id) => {
  return(dispatch) => {
  dispatch(fetchStart());
  dispatch(submissionInit());
  const apiEndpoint = GET_SUBMISSION.replace('id', id);
  userService.get(apiEndpoint).then((response) => {
      if (response) {
        dispatch(fetchSubmissionSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      if(error && error.data && error.data.error){
        dispatch(fetchError(error.data.error));
      }
      dispatch(fetchSubmissionFailure());
    });
  };
};


export const fetchSubmissionAttachmentsSuccess =  (attachmentData) => {
  return{
    type: FETCH_SUBMISSION_ATTACHMENTS_SUCCESS,
    payload: attachmentData
  }
};

export const fetchSubmissionAttachmentsFailure =  () => {
  return {
    type: FETCH_SUBMISSION_ATTACHMENTS_FAILURE,
  }
};


export const fetchSubmissionAttachments = (id) => {
  return  (dispatch) => {
    dispatch(fetchStart());
    const endPoint = FETCH_SUBMISSION_ATTACHMENTS.replace('id', id);
    userService.get(endPoint).then((response) => {
      if(response){
        dispatch(fetchSubmissionAttachmentsSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchSubmissionAttachmentsFailure());
    });
  }
};
// ALL SUBMISSIONS WITHOUT PAGINATIONS FOR OFFERS

export const fetchAllSubmissionsSuccess = (allSubmissions) => {
  return {
    type: FETCH_ALL_SUBMISSIONS_SUCCESS,
    payload: allSubmissions
  }
};

export const fetchAllSubmissionsFailure = () => {
  return {
    type: FETCH_ALL_SUBMISSIONS_FAILED,
  }
};


export const fetchAllSubmissions = (filterBy={status: null ,q:null ,consultant: null}) => {
  return(dispatch) => {
  dispatch(fetchStart());
  userService.get(GET_ALL_SUBMISSIONS, filterBy).then((response) => {
      if (response) {
        dispatch(fetchAllSubmissionsSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      // dispatch(fetchError(error.data.error));
      dispatch(fetchSubmissionsFailure());
    });
  };
};


// assessments
export const selectedSubmission = (selectedSubmissionId) => {
  return {
    type: SELECTED_SUBMISSION,
    payload: selectedSubmissionId
  }
};

// assessments
export const selectedAssessmentType = (assessmentType) => {
  return {
    type: SELECTED_ASSESSMEN_TYPE,
    payload: assessmentType
  }
};

export const fetchAssessmentSuccess = (assessments) => {
  return {
    type: FETCH_ASSESSMENT_SUCCESS,
    payload: assessments
  }
};

export const fetchAssessmentFailure = () => {
  return {
    type: FETCH_ASSESSMENT_FAILED,
  }
};


export const fetchAssessments = (submissionId) => {
  return(dispatch) => {
  dispatch(fetchStart());
  const apiEndpoint = GET_ASSESSMENTS.replace('submissionId', submissionId);
  userService.get(apiEndpoint).then((response) => {
      if (response) {
        dispatch(fetchAssessmentSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchAssessmentFailure());
    });
  };
};

// assessment List
export const fetchAssessmentListSuccess = (assessmentList) => {
  return {
    type: FETCH_ASSESSMENT_LIST_SUCCESS,
    payload: assessmentList
  }
};

export const fetchAssessmentListFailure = () => {
  return {
    type: FETCH_ASSESSMENT_LIST_FAILED,
  }
};


export const fetchAssessmentsList = (filterBy={page: 1 ,marketer:null ,from_date: null ,to_date: null ,team_profiles:null ,include_self:false}) => {
  return(dispatch) => {
  dispatch(fetchStart());
  userService.get(GET_ASSESSMENTS_LIST, filterBy).then((response) => {
      if (response) {
        dispatch(fetchAssessmentListSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchAssessmentListFailure());
    });
  };
};
// team assessment

export const fetchTeamAssessmentsSuccess = (assessmentList) => {
  return {
    type: FETCH_TEAM_ASSESSMENTS_SUCCESS,
    payload: assessmentList
  }
};

export const fetchTeamAssessmentsFailure = () => {
  return {
    type: FETCH_TEAM_ASSESSMENTS_FAILED,
  }
};


export const fetchTeamAssessments = (filterBy={page: 1}) => {
  return(dispatch) => {
  dispatch(fetchStart());
  userService.get(GET_TEAM_ASSESSMENTS ,filterBy).then((response) => {
      if (response) {
        dispatch(fetchTeamAssessmentsSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchTeamAssessmentsFailure());
    });
  };
};

export const editAssessmentSuccess = (assessments) => {
  return {
    type: EDIT_ASSESSMENT_SUCCESS,
    payload: assessments
  }
};

export const editAssessmentFailure = () => {
  return {
    type: EDIT_ASSESSMENT_FAILED,
  }
};

export const editAssessments = (submissionId=null, assessmentId=null, values=null, isListPageCall=false) => {
  return(dispatch) => {
  dispatch(fetchStart());
  var apiEndpoint = EDIT_ASSESSMENTS.replace('assessmentId', assessmentId);
  if(values["status"] =="r"){
    apiEndpoint = apiEndpoint.concat("update-status/");
  }  
  userService.patch(apiEndpoint, values).then((response) => {
      if (response) {
        dispatch(handleRemoveDisableSubmitButton('edit_assessment_form'))
        dispatch(fetchSuccess());
        dispatch(editAssessmentSuccess(response));
        if(isListPageCall){
          dispatch(fetchAssessmentsList());
        }else{
          dispatch(fetchAssessments(submissionId));
        }
        dispatch(handleErrorResponse({"editAssessment": "success"}));
        dispatch(showMessage("Assessment Edited Successfully"))
      }
    }).catch(function (error) {
      dispatch(handleRemoveDisableSubmitButton('edit_assessment_form'))
      dispatch(fetchError(error.data.error));
      dispatch(handleErrorResponse({"editAssessment": error}));
      dispatch(editAssessmentFailure());
    });
  };
};



export const deleteAssessmentSuccess =  () => {
  return{
    type: DELETE_ASSESSMENT_SUCCESS,
  }
};

export const deleteAssessmentFailure =  () => {
  return {
    type: DELETE_ASSESSMENT_FAILURE,
  }
};

export const deleteAssessment = (assessmentId, submissionId, isListPageCall=false ) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const endPoint = DELETE_ASSESSMENTS.replace('assessmentId', assessmentId);
    userService.destroy(endPoint).then((response) => {
      // if(response){
        dispatch(deleteAssessmentSuccess());
        if(isListPageCall){
          dispatch(fetchAssessmentsList());
        }else{
          dispatch(fetchAssessments(submissionId));
        }
        dispatch(fetchSuccess());
        dispatch(showMessage("Assessment Deleted Successfully!"));

      // }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(deleteAssessmentFailure());
    });
  }
};

export const handleSubmissionDelete = (subId ) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const endPoint = DELETE_SUBMISSION.replace('submissionId', subId);
    userService.destroy(endPoint).then((response) => {
      dispatch(fetchSubmissions());
      dispatch(fetchSuccess());
      dispatch(showMessage("Submission Deleted Successfully!"));
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      if(error && error.data && error.data.errors){
        dispatch(fetchError(error.data.errors));
      }
    });
  }
};

