import {axiosAPI, setNewHeaders} from "../../util/Api";

export const authService = {
  obtainToken,
  refreshToken,
  post
};



function obtainToken(apiEndpoint, payload) {
    return axiosAPI.post(apiEndpoint, payload).then((response) => {
      if( !('error_code'  in response.data)){
          setNewHeaders(response);
      }
        return response.data;
    }).catch(function (error) {
      return error.response.data.errors
      })
}

function refreshToken(apiEndpoint, payload) {
    return axiosAPI.post(apiEndpoint, payload).then((response) => {
        setNewHeaders(response);
        return response;
    })
}

function post(apiEndpoint, payload){
    return axiosAPI.post(apiEndpoint, payload).then((response)=>{
       return response;
    })
}
