import {
    FETCH_NOTIFICATIONS_SUCCESS,
    FETCH_NOTIFICATIONS_FAILURE,
    FETCH_ALL_OFFERS_COUNT,
    FETCH_ALL_MARKETER_DASHBOARD_ANALYTICS,
    FETCH_ALL_COUNT_TO_STATUS_MARKETING,
    FETCH_ASSIGNED_CONSULTANT,
    FETCH_PNL_OFFERS,
    READ_NOTIFICATIONS_SUCCESS,
    FETCH_TOTAL_ONGING_OFFERS,
    FETCH_RECRUITERS_CONSULTANT_CONST,
    UPDATE_START_DATE_CONSULTANT_COUNT_TABLE,
    UPDATE_END_DATE_CONSULTANT_COUNT_TABLE,
    UPDATE_START_DATE_OFFERS_FOR_MONTH_TABLE,
    UPDATE_END_DATE_OFFERS_FOR_MONTH_TABLE,
    FETCH_PROJECT_TYPE_PNL_DATA,
    FETCH_TOTAL_DATA_MARKETING_PROFILE_TOTAL,
    FETCH_ASSIGNED_CONSULTANT_DATA_BY_GROUP,
    FETCH_MONTHWISE_SUBMISSION_AND_ASSESSMENT_SUCCESS,
    FETCH_CURRENT_WEEK_OFFERS_LIST_SUCCESS

  } from "../../constants/ActionTypes";
  
  const INIT_STATE = {
    notification: null,
    notificationCount:0,
    notificationList:[],
    yearlyOffersCount:[],
    marketerAnalysis: [],
    statusMarketingCount: 0,
    assignedConsultant:[],  
    pnlOffers: [],  
    totalOngoingOffers: [],
    recruiterConsultant: [],
    consultantCountTableStartDate: 0,
    consultantCountTableEndDate:0,
    offersForMonthTableStartDate:0,
    offersForMonthTableEndDate:0,
    pnlProjectTypeData:[],
    marketingProfileTotalTableData:[],
    assignedConsultantByGroup:[],
    monthwiseAssessmentAndSubmission:null,
    currentWeekOffersList: null,
  };
  
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {

      case FETCH_NOTIFICATIONS_SUCCESS: {
        return {
          ...state,
          notification: action.payload,
          notificationCount: action.payload.unread,
          notificationList: [...state.notificationList, ...action.payload.results],
        }
      }
      case FETCH_NOTIFICATIONS_FAILURE: {
        return {
          ...state,
          notification: null
        }
      }
      case READ_NOTIFICATIONS_SUCCESS: {
        return {
          ...state,
          notificationCount: action.payload
        }
      }
      
      case FETCH_ALL_OFFERS_COUNT: {
        return {
          ...state,
          yearlyOffersCount: action.payload
        }
      }
      case FETCH_ALL_MARKETER_DASHBOARD_ANALYTICS: {
        return {
          ...state,
          marketerAnalysis: action.payload
        }
      }
      case FETCH_ALL_COUNT_TO_STATUS_MARKETING: {
        return {
          ...state,
          statusMarketingCount: action.payload
        }
      }

      case FETCH_ASSIGNED_CONSULTANT: {
        return {
          ...state,
          assignedConsultant: action.payload
        }
      }

      case FETCH_PNL_OFFERS: {
        return {
          ...state,
          pnlOffers: action.payload
        }
      }
      case FETCH_TOTAL_ONGING_OFFERS: {
        return {
          ...state,
          totalOngoingOffers: action.payload
        }
      }
      case FETCH_RECRUITERS_CONSULTANT_CONST: {
        return {
          ...state,
          recruiterConsultant: action.payload
        }
      }
      case FETCH_PROJECT_TYPE_PNL_DATA:{
        return {
          ...state,
          pnlProjectTypeData: action.payload
        }
      }
      case FETCH_TOTAL_DATA_MARKETING_PROFILE_TOTAL: {
        return {
          ...state,
          marketingProfileTotalTableData: action.payload
        }
      }
      case FETCH_ASSIGNED_CONSULTANT_DATA_BY_GROUP: {
        return {
          ...state,
          assignedConsultantByGroup: action.payload
        }
      }
      case UPDATE_START_DATE_CONSULTANT_COUNT_TABLE: {
        return {
          ...state,
          consultantCountTableStartDate: action.payload
        }
      }
      case UPDATE_END_DATE_CONSULTANT_COUNT_TABLE: {
        return {
          ...state,
          consultantCountTableEndDate: action.payload
        }
      }
      case UPDATE_START_DATE_OFFERS_FOR_MONTH_TABLE: {
        return {
          ...state,
          offersForMonthTableStartDate: action.payload
        }
      }
      case UPDATE_END_DATE_OFFERS_FOR_MONTH_TABLE: {
        return {
          ...state,
          offersForMonthTableEndDate: action.payload
        }
      }
      case FETCH_MONTHWISE_SUBMISSION_AND_ASSESSMENT_SUCCESS: {
        return {
          ...state,
          monthwiseAssessmentAndSubmission: action.payload.month_data
        }
      }
      case FETCH_CURRENT_WEEK_OFFERS_LIST_SUCCESS: {
        return {
          ...state,
          currentWeekOffersList: action.payload
        }
      }
      default:
        return state;
    }
  }
  