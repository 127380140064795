import React, {useEffect, useState} from 'react';
import Addresspage from './Addresspage';
import Attachmentpage from './Attachmentpage';
import Emergencydetails from './Emergencydetails';
import moment from 'moment';
import { CONSULTANT_BACKGROUND } from "../../constants/ApiEndpoints";
import { Card, Button, Form, message } from 'antd';
import {fetchStart, fetchSuccess} from "../../appRedux/actions/Common";
import { useDispatch } from 'react-redux';
import axios from 'axios';

function index() {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1);
  const [formDataBackground, setFormDataBackground] = useState({});
  const [form] = Form.useForm();

  const nextPage = () => {
        form.validateFields()
        .then(values => {
              if(values && values.bachelors_degree && values.bachelors_degree.fileList && values.bachelors_degree.fileList.length === 0 ){
                message.error("Please Upload Bachelor's Degree")
                return
              }
              if(values && values.bachelors_last_marksheet && values.bachelors_last_marksheet.fileList && values.bachelors_last_marksheet.fileList.length === 0 ){
                message.error("Please Upload Last Year Mark Sheet")
                return
              }
              if(values && values.transcript && values.transcript.fileList && values.transcript.fileList.length === 0 ){
                message.error("Please Upload Transcript")
                return
              }
              if(values && values.us_equivalent && values.us_equivalent.fileList && values.us_equivalent.fileList.length === 0 ){
                message.error("Please Upload US Equivalent")
                return
              }
              if(values && values.ifMaster && values.ifMaster === 'yes' && values.masters_degree && values.masters_degree.fileList && values.masters_degree.fileList.length === 0 ){
                message.error("Please Upload Masters Degree")
                return
              }
              if(values && values.ifMaster && values.ifMaster === 'yes' && values.masters_last_marksheet && values.masters_last_marksheet.fileList && values.masters_last_marksheet.fileList.length === 0 ){
                message.error("Please Upload Masters Last Marksheet")
                return
              }
            localStorage.setItem('formDataBackground', JSON.stringify({ ...formDataBackground, ...values }));
            setCurrentPage(currentPage + 1);
        })
        .catch(errorInfo => {
            message.error("Upload required Details ")
            console.error("Validation failed:", errorInfo);
        });
    };
  
    useEffect(() => {
        const storedData = localStorage.getItem('formDataBackground');
        if (storedData) {
          const formData = JSON.parse(storedData);
            delete formData.bachelors_degree;
            delete formData.bachelors_last_marksheet;
            delete formData.transcript;
            delete formData.us_equivalent;
            delete formData.masters_degree;
            delete formData.masters_last_marksheet;
            delete formData.i9_form;
            delete formData.ssn_back_copy;
            delete formData.ssn_front_copy;

          setFormDataBackground(formData);
          localStorage.setItem('formDataBackground', JSON.stringify({ ...formData}));
        }
      }, []);

  const prevPage = () => {
    localStorage.setItem('formDataBackground', JSON.stringify({ ...formDataBackground}));
    setCurrentPage(currentPage - 1);
  };
  
  
      const handleSubmit = ()=>{
        form.validateFields()
        .then(values => {
          if(values && values.i9_form && values.i9_form.fileList && values.i9_form.fileList.length === 0 ){
            message.error("Please Upload I-9 Form")
            return
          }
          if(values && values.ssn_front_copy && values.ssn_front_copy.fileList && values.ssn_front_copy.fileList.length === 0 ){
            message.error("Please Upload SSN Front Copy")
            return
          }
          if(values && values.ssn_back_copy && values.ssn_back_copy.fileList && values.ssn_back_copy.fileList.length === 0 ){
            message.error("Please Upload SSN Back Copy")
            return
          }
        const dataToSend = prepareFormData(formDataBackground);
        // if(formDataBackground && formDataBackground.ifMaster && formDataBackground.ifMaster === 'no'){
        //   if ('masters_degree' in formDataBackground) {
        //     delete dataToSend.masters_degree;
        //   }
        //   if ('masters_last_marksheet' in formDataBackground) {
        //       delete dataToSend.masters_last_marksheet;
        //     }
        //   }
        const currentDomain = window.location.hostname;
        var endpoint = '';
        if (currentDomain === 'dev.stell.one') {
            endpoint = "https://dev-backend.stell.one/api/consultants/bgc-details/";
        } 
        else if (currentDomain === 'stell.one') {
            endpoint = "https://prod-backend.stell.one/api/consultants/bgc-details/";
        }
        else if(currentDomain === 'staging.stell.one'){
            endpoint = "https://backend.stell.one/api/consultants/bgc-details/";
        }
        else if (currentDomain === 'localhost' || currentDomain === '127.0.0.1' || currentDomain === '0.0.0.0') {
            endpoint = "http://localhost:8000/api/consultants/bgc-details/";
            // endpoint = "https://backend.stell.one/api/consultants/bgc-details/";

        }else{
            endpoint = "https://backend.stell.one/api/consultants/bgc-details/";
        }
        dispatch(fetchStart());

        axios.post(endpoint, dataToSend, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {
            dispatch(fetchSuccess());
            message.success('Form Submitted Successfully!');
            localStorage.removeItem('formDataBackground')
            setFormDataBackground({})
            form.resetFields()
        })
        .catch((error) => {
            message.error('Error Submitting the form!');
            console.log('error', error);
        });
      })
      .catch(errorInfo => {
          console.error("Validation failed:", errorInfo);
      });
      }

    const prepareFormData = (data) => {
          const formData = new FormData();
          formData.append('email', data.email);
          formData.append("token", data.token);
          const formattedAddresses = data.address.map(addr => ({
            ...addr,
            move_in: moment(addr.move_in).format('YYYY-MM-DD'),
            move_out: moment(addr.move_out).format('YYYY-MM-DD'),
            country:'USA'
          }));
          formData.append('addresses', JSON.stringify(formattedAddresses));
          formData.append('emergency_contacts', JSON.stringify([
            data.emergency_contact_1,
            data.emergency_contact_2
          ]));

          // Appending files to formData
        var fileKeys = []
        if(data && data.ifMaster && data.ifMaster === 'no'){
          fileKeys = [
            'bachelors_degree', 'bachelors_last_marksheet', 'transcript',
            'us_equivalent', 
            'i9_form', 'ssn_front_copy', 'ssn_back_copy'
          ];
        }
        else{
          fileKeys = [
            'bachelors_degree', 'bachelors_last_marksheet', 'transcript',
            'us_equivalent', 'masters_degree', 'masters_last_marksheet',
            'i9_form', 'ssn_front_copy', 'ssn_back_copy'
          ];
        }
          for (const key of fileKeys) {
            if (data[key] && data[key].file) {
              formData.append(key, data[key].fileList[0].originFileObj);
            }
          }
          return formData;
    };


return (
  <div style={styles.div}>
   <Card style={styles.card} bodyStyle={styles.cardBody}>
      {currentPage === 1 && (
          <Addresspage formDataBackground={formDataBackground} setFormDataBackground={setFormDataBackground} form={form} />
      )}
      {currentPage === 2 && (
          <Attachmentpage formDataBackground={formDataBackground} setFormDataBackground={setFormDataBackground} form={form} />
      )}
      {currentPage === 3 && (
          <Emergencydetails formDataBackground={formDataBackground} setFormDataBackground={setFormDataBackground} form={form}/>
      )}
      {currentPage > 1 && <Button onClick={prevPage}>Back</Button>}
      {currentPage < 3 ? <Button onClick={nextPage}>Next</Button>: <Button type="button" onClick={handleSubmit}>Submit</Button>} 
    </Card>
   </div>
)
}

export default index

const styles = {
  card: {
    margin:"2% 10% 0% 10%"
  },
  div : {
      overflow:'auto',
      height:'100vh' 
  }
};