import React from "react";
import {Redirect, Switch} from "react-router";
import {PropsRoute} from "../../util/routeRestriction";
import asyncComponent from "../../util/asyncComponent";

// const AsyncProjectCoordinatorSupportPerson = asyncComponent(() => import(''));
const AsyncEngieersList = asyncComponent(() => import('./engineers/index'));
const AsyncEngieerEdit = asyncComponent(() => import('./engineers/edit'));
const AsyncTimeSheet = asyncComponent(() => import('./timeSheet/index'));

const Engineer = (props) => (
  <Switch>
    <Redirect exact from={`${props.match.url}/engineering`} to={`${props.match.url}`}/>
        {/* <PropsRoute path={`${props.match.url}/support_person`} dispatch={props.dispatch}
          component={AsyncProjectCoordinatorSupportPerson} user={props.user}/> */}
        <PropsRoute path={`${props.match.url}/engineers/list`} dispatch={props.dispatch}
          component={AsyncEngieersList} user={props.user}/>
        <PropsRoute path={`${props.match.url}/engineers/edit/:engineerId`} 
          dispatch={props.dispatch} component={AsyncEngieerEdit} user={props.user}/>
        <PropsRoute path={`${props.match.url}/timesheet/:offerId`} 
          dispatch={props.dispatch} component={AsyncTimeSheet} user={props.user}/>

  </Switch>
);

export default Engineer;
