import React, {useState, useEffect} from 'react'
import { Card, Button, Form, message } from 'antd';
import Page1 from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";
import moment from 'moment';
import {userService} from "../../appRedux/services";
import { CONSULTANT_ONBOARDING } from "../../constants/ApiEndpoints";
import {fetchStart, fetchSuccess} from "../../appRedux/actions";
import { useDispatch } from 'react-redux';
import axios from 'axios';



function Onboarding() {
    const [currentPage, setCurrentPage] = useState(1);
    const [formData, setFormData] = useState({});
    const [form] = Form.useForm();
    const dispatch = useDispatch()

    const nextPage = () => {
        form.validateFields()
        .then(values => {
            if(values && values.attachment_type && values.attachment_type === 'state_id' && values.state_id && values.state_id.fileList && values.state_id.fileList.length === 0 ){
                message.error("Add Attach State ID")
                return
            }
            if(values && values.attachment_type && values.attachment_type === 'driving_license' && values.driving_license_front && values.driving_license_front.fileList && values.driving_license_front.fileList.length === 0 ){
                message.error("Driving License (Front)")
                return
            }
            localStorage.setItem('formData', JSON.stringify({ ...formData, ...values }));
            if(values && values.visa){
                setFormData({ ...formData, visa: values.visa })
            }
            setCurrentPage(currentPage + 1);
        })
        .catch(errorInfo => {
            console.error("Validation failed:", errorInfo);
        });
    };
  
    const prevPage = () => {
        localStorage.setItem('formData', JSON.stringify({ ...formData}));
        setCurrentPage(currentPage - 1);
    };
    useEffect(() => {
        const storedData = localStorage.getItem('formData');
        console.log('storedData', JSON.parse(storedData))
        if (storedData) {
            const formData = JSON.parse(storedData);
            delete formData.state_id;
            delete formData.driving_license_front;
            delete formData.gc_copy; 
            delete formData.gc_ead_copy;
            delete formData.us_passport_copy;
            delete formData.w2_contract;
            delete formData.h4_ead_copy;
            delete formData.opt_copy;
            delete formData.cpt_copy;
            delete formData.other_copy;

          setFormData(formData);
          localStorage.setItem('formData', JSON.stringify({ ...formData}));
        //   setFormData(JSON.parse(storedData));
        }
      }, []);

      useEffect(()=>{
        const storedData = localStorage.getItem('formData');
        if(storedData === null ){
            setFormData({})
            form.resetFields();
        }
      },[currentPage])

      const handleSubmit = ()=>{
        form.validateFields()
        .then(values => {
            if(values && values.gc_ead_copy && values.gc_ead_copy.fileList && values.gc_ead_copy.fileList.length === 0 ){
                message.error("Add Attach GC EAD Copy")
                return
            }
            if(values && values.w2_contract && values.w2_contract.fileList && values.w2_contract.fileList.length === 0 ){
                message.error("Add Attach W-2 Contract With American Chase")
                return
            }
            if(values && values.h4_ead_copy && values.h4_ead_copy.fileList && values.h4_ead_copy.fileList.length === 0 ){
                message.error("Add Attach H4 EAD Copy")
                return
            }
            if(values && values.opt_copy && values.opt_copy.fileList && values.opt_copy.fileList.length === 0 ){
                message.error("Add Attach OPT Copy")
                return
            }
            if(values && values.cpt_copy && values.cpt_copy.fileList && values.cpt_copy.fileList.length === 0 ){
                message.error("Add Attach CPT Copy")
                return
            }
            if(values && values.other_copy && values.other_copy.fileList && values.other_copy.fileList.length === 0 ){
                message.error("Add Attach Other Copy")
                return
            }
            
        const transformDataForPost = (formData) => {
            if((formData && !formData.ext_Code || formData.ext_Code === "") && formData.countryCode && formData.phone){
                const formattedPhone = `${formData.countryCode} ${formData.phone}`
                formData.phone_number = formattedPhone;
              }
              if(formData &&formData.ext_Code && formData.countryCode && formData.phone){
                const formattedPhone = `${formData.countryCode} ${formData.phone}, ${formData.ext_Code}`
                formData.phone_number = formattedPhone;
              }
              if( formData && formData.ext_Code || formData.ext_Code === undefined){
                delete formData.ext_Code;
              }
              if( formData && formData.phone || formData.phone === undefined){
                delete formData.phone;
              }
              if( formData && formData.countryCode || formData.countryCode === undefined){
                delete formData.countryCode;
              }
            const {
                first_name, middle_name, last_name, email, alternate_emails, phone, alternate_phone, ssn, dob, current_address,
                token, degree_1, institute_1, university_1, area_of_study_1, start_year_1, end_year_1,
                degree_2, institute_2, university_2, area_of_study_2, start_year_2, end_year_2,
                visa, othervisa, emergency_name, emergency_email, emergency_phone, emergency_relation,visa_at_us_entry ,current_visa_source,
                countryCode , ext_Code, phone_number,
                ...uploadFields
            } = formData;
            const filteredAlternateEmails = [
                alternate_emails.email1
            ].filter(email => email && email.trim() !== "");
        
        
            return {
                first_name, middle_name, last_name, email, phone,ssn, alternate_phone, current_address, token,
                dob: moment(dob).format('YYYY-MM-DD'),
                alternate_emails: filteredAlternateEmails,
                alternate_email: alternate_emails && alternate_emails.email1 ? alternate_emails.email1:"",
                visa: visa == 'Other'?othervisa: visa,
                phone_number : phone_number,
                
                visa_at_us_entry:visa_at_us_entry !== undefined ? visa_at_us_entry:"",
                current_visa_source:current_visa_source !== undefined ? current_visa_source:"",
                bachelors:{
                        degree: degree_1,
                        institute: institute_1,
                        university: university_1,
                        area_of_study: area_of_study_1,
                        ...(start_year_1 && { start_year: moment(start_year_1).format('YYYY-MM-DD') }),
                        ...(end_year_1 && { end_year: moment(end_year_1).format('YYYY-MM-DD') })
                    },
                 ...(degree_2 && { masters: {
                        degree: degree_2,
                        institute: institute_2,
                        university: university_2,
                        area_of_study: area_of_study_2,
                        ...(start_year_2 && { start_year: moment(start_year_2).format('YYYY-MM-DD') }),
                        ...(end_year_2 && { end_year: moment(end_year_2).format('YYYY-MM-DD') })
                    }}),
                emergency_contacts: {
                    name: emergency_name,
                    email: emergency_email,
                    contact: emergency_phone,
                    relation: emergency_relation
                },
                ...uploadFields
            };
       
        };
        
        const postData = transformDataForPost(formData);
        function convertToDataToSend(postData) {
            const dataToSend = new FormData();
        
            Object.entries(postData).forEach(([key, value]) => {
                if(key === 'visa_at_us_entry' && value === ""){
                    dataToSend.append(key, "");
                    return
                }
                if(key === 'current_visa_source' && value === ""){
                    dataToSend.append(key, "");
                    return
                }
                if (value == undefined || value == "" || typeof value == undefined || value == "undefined" || value === null) {
                    return;
                } else if (value && value.fileList) {
                    // Iterate through file list and append each file to the FormData
                    value.fileList.forEach(file => {
                        dataToSend.append(key, file.originFileObj);
                    });
                } else if (typeof value === 'object') {
                    dataToSend.append(key, JSON.stringify(value));
                } else {
                    dataToSend.append(key, value);
                }
            });
            return dataToSend;
        }
        
        const uploadData = convertToDataToSend(postData);

        // dispatch(fetchStart());
        // userService.post(CONSULTANT_ONBOARDING, uploadData).then((response) => {
        //     if (response) {
        //         dispatch(fetchSuccess());
        //       dispatch(showMessage(`Form Submitted Successfully!`));
        //     //    localStorage.removeItem('formData')
        //     }
        //   }).catch(function (error) {
        //     dispatch(fetchError(""));
        //    console.log('errror', error)
        //   });

        const currentDomain = window.location.hostname;
            var endpoint = '';
            if (currentDomain === 'dev.stell.one') {
                endpoint = "https://dev-backend.stell.one/api/consultants/on-boarding/";
            } 
            else if (currentDomain === 'stell.one') {
                endpoint = "https://prod-backend.stell.one/api/consultants/on-boarding/";
            }
            else if(currentDomain === 'staging.stell.one'){
                endpoint = "https://backend.stell.one/api/consultants/on-boarding/";
            }
            else if (currentDomain === 'localhost' || currentDomain === '127.0.0.1' || currentDomain === '0.0.0.0') {
                endpoint = "http://localhost:8000/api/consultants/on-boarding/";
                // endpoint = "https://prod-backend.stell.one/api/consultants/on-boarding/";

            }else{
                endpoint = "https://backend.stell.one/api/consultants/on-boarding/";
            }

            dispatch(fetchStart());

            axios.patch(endpoint, uploadData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                dispatch(fetchSuccess());
                message.success('Form Submitted Successfully!');
                localStorage.removeItem('formData');
                setCurrentPage(1)
                form.resetFields();
            })
            .catch((error) => {
                message.error('Error Submitting the form!');
                console.log('error', error);
                if (error && error.response && error.response.data && error.response.data.errors && typeof error.response.data.errors === 'object') {
                    const errorkeys = Object.keys(error.response.data.errors);
                    errorkeys.forEach((key) => {
                      const errorMessage = error.response.data.errors[key];
                      message.error(errorMessage);
                    });
                  }
            });
        })
        .catch(errorInfo => {
            console.error("Validation failed:", errorInfo);
        });
      }

  return (
    <div style={styles.div}>
     <Card style={styles.card} bodyStyle={styles.cardBody}>
        {currentPage === 1 && (
            <Page1 formData={formData} setFormData={setFormData} form={form} />
        )}
        {currentPage === 2 && (
            <Page2 formData={formData} setFormData={setFormData} form={form} />
        )}
        {currentPage === 3 && (
            <Page3 formData={formData} setFormData={setFormData} form={form}/>
        )}
        {currentPage > 1 && <Button onClick={prevPage}>Back</Button>}
        {currentPage < 3 ? <Button onClick={nextPage}>Next</Button>: <Button type="button" onClick={handleSubmit}>Submit</Button>} 
      </Card>
     </div>
  )
}

export default Onboarding
const styles = {
    card: {
      margin:"2% 10% 0% 10%"
    },
    div : {
        overflow:'auto',
        height:'100vh' 
    }
  };